import { IconButton, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import TablePaginationActions from '../../shared/TablePaginationActions';
import {
  colors,
  defaultPagination,
  Paths,
  PaginationType,
  periodOptions,
  PeriodTypes,
  productTypeOptions,
  ProductTypes,
  ProductsViewAttributes,
  Role,
} from '../../../utils/constants';
import SelectComponent from '../../input/form/select';
import DatePickerComponent from '../../input/form/datepicker';
import InputComponent from '../../input/form/input';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    tableTitle: any;
    button: any;
    filters: any;
    table: any;
  } => ({
    view: {
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    tableTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      background: colors.green,
      padding: '1rem 4rem',
      border: 'none',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '1rem',
      '& > span': {
        marginBottom: '14px',
      },
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
    table: {
      background: colors.lightBlue,
      padding: '2rem',
      borderRadius: '4px',
      display: 'grid',
      '& .subtitle': {
        textAlign: 'start',
        marginBottom: '1rem',
      },
      '& .MuiTableContainer-root': {
        background: 'inherit',
        boxShadow: 'none',
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${colors.grayBlue}`,
        },

        '& th': {
          color: colors.darkGrayBlue,
        },
        '& td': {
          fontSize: '12px',
          color: colors.darkBlue,
          [theme.breakpoints?.down('lg')]: {
            fontSize: '0.75rem',
          },
          '& svg': {
            cursor: 'pointer',
            margin: '0 0.25rem',
          },
        },
        '& td.MuiTableCell-footer ': {
          borderBottom: 'none',
        },
      },
    },
  }),
);

const ProductsView = ({
  products,
  pagination,
  handleChangePageSize,
  handlePageChange,
  fetchProductsMethod,
  productTypeFilter,
  setProductTypeFilter,
  yearFilterValue,
  setYearFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  classroomFilterValue,
  setClassroomFilterValue,
  periodFilterValue,
  setPeriodFilterValue,
  courseFilterValue,
  setCourseFilterValue,
  nameFilterValue,
  setNameFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
}: {
  products: ProductsViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  fetchProductsMethod: ({
    newPagination,
    year,
    period,
  }: {
    newPagination?: PaginationType | undefined;
    year?: Date | null;
    period?: PeriodTypes | string;
    type?: string;
    company_filter?: string;
  }) => Promise<void>;
  productTypeFilter: ProductTypes | string;
  setProductTypeFilter: React.Dispatch<React.SetStateAction<ProductTypes | string>>;
  yearFilterValue: Date | null;
  setYearFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  ktwelveFilterValue: string;
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>;
  classroomFilterValue: string;
  setClassroomFilterValue: React.Dispatch<React.SetStateAction<string>>;
  periodFilterValue: PeriodTypes | string;
  setPeriodFilterValue: React.Dispatch<React.SetStateAction<PeriodTypes | string>>;
  courseFilterValue: string;
  setCourseFilterValue: React.Dispatch<React.SetStateAction<string>>;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_admin,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_admin ? ['Unidade'] : []), 'Nome', 'Descrição', 'Ano', 'Curso', 'Série', 'Turma', 'Ações'];

  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Produtos</span>
        <button className='green small' onClick={() => history.push(Paths.PRODUCTS_FORM)}>
          <span>Criar Produto</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div className={classes.filters}>
          {is_above_school_admin && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchProductsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Tipo'
            small
            options={productTypeOptions}
            input={{
              value: productTypeFilter,
              onChange: (e: any) => {
                setProductTypeFilter(e.target.value);
                fetchProductsMethod({
                  type: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setProductTypeFilter('');
              fetchProductsMethod({
                type: '',
              });
            }}
          />
          <DatePickerComponent
            placeholder='Ano letivo'
            small
            datePickerProps={{
              views: ['year'],
              inputFormat: 'yyyy',
              allowSameDateSelection: true,
            }}
            input={{
              value: yearFilterValue,
              name: 'year',
              onChange: (e: Date) => {
                setYearFilterValue(e);
                fetchProductsMethod({
                  year: e,
                });
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por curso`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: courseFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setCourseFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por série`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: ktwelveFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKtwelveFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por turma`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: classroomFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setClassroomFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Turno'
            small
            options={periodOptions}
            clearable
            clearFieldFunction={() => {
              setPeriodFilterValue('');
              fetchProductsMethod({
                period: '',
              });
            }}
            input={{
              value: periodFilterValue,
              onChange: (e: any) => {
                setPeriodFilterValue(e.target.value);
                fetchProductsMethod({
                  period: e.target.value,
                });
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table data-testid='products-table'>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => {
                return (
                  <TableRow key={product.id} id={product.id}>
                    {is_above_school_admin && <TableCell>{product.company}</TableCell>}
                    <TableCell> {product.name} </TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell>{product.year}</TableCell>
                    <TableCell>{product.course}</TableCell>
                    <TableCell>{product.ktwelve}</TableCell>
                    <TableCell>{product.classroom}</TableCell>
                    <TableCell>
                      <Tooltip title='Editar produto'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.PRODUCTS_FORM, {
                              product_id: product.id,
                              company_id: product.company_id
                            });
                          }}
                        >
                          <PenIcon style={{ color: colors.blue }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Copiar produto'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.PRODUCTS_FORM, {
                              product_id: product.id,
                              copy: true,
                            });
                          }}
                        >
                          <AddToPhotosIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ProductsView;
