/* eslint-disable camelcase */
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, isValid } from 'redux-form';
import { RootState } from '../store/configureStore';
import {
  AccountsAttributes,
  ContractAttributes,
  BillingAttributes,
  DefaultOptionType,
  InvoiceAttributes,
  InvoiceFormAttributes,
  InvoiceItemAttributes,
  NestedInvoiceItemAttributes,
  NestedWalletCreditAttributes,
  RegistrationAttributes,
  RepresentativeAttributes,
  WalletAttributes,
  WalletCreditAttributes,
} from '../utils/constants';
import { CREATE_INVOICE, FETCH_INVOICE, UPDATE_INVOICE } from '../store/invoices';
import InvoiceForm from '../components/form/InvoiceForm';
import { verifyDifferentValues } from '../utils/functions';
import { filter, find, isEmpty, isNil, keys, map, omit } from 'lodash';
import Loading from '../components/loading/Loading';
import { WalletCreditJson } from '../store/wallet_credits';
import { InvoiceItemJson } from '../store/invoice_items';
import { BillingJson } from '../store/billings';
import { RepresentativeJson } from '../store/representatives';
import { AccountJson } from '../store/accounts';
import { RegistrationJson } from '../store/registrations';
import { ContractJson } from '../store/contracts';
import { WalletJson } from '../store/wallets';
import { CompanyConfigParams } from '../store/companies';

const invoiceItemUpdater = (
  currentInvoiceItems: NestedInvoiceItemAttributes[],
  initialInvoiceItems: NestedInvoiceItemAttributes[],
) => {
  const sorted: NestedInvoiceItemAttributes[] = [];
  currentInvoiceItems.forEach((invoice_item) => {
    const related = find(initialInvoiceItems, (initial) => initial.id === invoice_item.id);
    if (isNil(related)) {
      sorted.push(invoice_item);
      return;
    }
    const result = verifyDifferentValues(invoice_item, related, [
      'id',
      '_destroy',
      'code',
      'description',
    ]) as NestedInvoiceItemAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedInvoiceItemAttributes[];
};

const walletCreditUpdater = (
  currentWalletCredits: NestedWalletCreditAttributes[],
  initialWalletCredits: NestedWalletCreditAttributes[],
) => {
  const sorted: NestedInvoiceItemAttributes[] = [];
  currentWalletCredits.forEach((wallet_credit) => {
    const related = find(initialWalletCredits, (initial) => initial.id === wallet_credit.id);
    if (isNil(related)) {
      sorted.push(wallet_credit);
      return;
    }
    const result = verifyDifferentValues(wallet_credit, related, ['id', '_destroy']) as NestedInvoiceItemAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedInvoiceItemAttributes[];
};

const InvoiceFormContainer = ({
  handleFetchInvoices,
  handleTabChange,
  invoice,
  load_resources = false,
}: {
  handleFetchInvoices: () => Promise<void>;
  handleTabChange: (_: any, newValue: string) => void;
  invoice: InvoiceAttributes;
  load_resources?: boolean;
}) => {
  const initial_data = {
    billings: [] as BillingAttributes[],
    contracts: [] as ContractAttributes[],
    invoice_items: [] as InvoiceItemAttributes[],
    representatives: [] as RepresentativeAttributes[],
    wallet_credits: [] as WalletCreditAttributes[],
    wallets: [] as WalletAttributes[],
    accounts: [] as AccountsAttributes[],
    registrations: [] as RegistrationAttributes[],
    registration: null as RegistrationAttributes | null,
  };
  const formName = 'invoiceForm';
  const [initialValues, setInitialValues] = React.useState<InvoiceFormAttributes | null>(null);
  const [company_config_params, setCompanyConfigParams] = React.useState<CompanyConfigParams|null>(null)
  const [data, setData] = React.useState<typeof initial_data>(initial_data);
  const [representativeOptions, setRepresentativeOptions] = React.useState<DefaultOptionType[]>([]);
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const invoice_form_values = getFormValues(formName)(state) as InvoiceFormAttributes;
  const use_invoice_form_values = invoice_form_values || initialValues;
  const isFormValid = isValid(formName)(state);

  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: [
                'representative.account.wallet.wallet_credits',
                'billings.invoice_items',
                'billings.billable',
                'representative.account.user',
                'registration.account',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const wallet_credits = map(
        filter(
          included,
          (incl) => incl.type === 'wallet_credits' && incl.attributes.invoice_id === ~~invoice.id,
        ) as WalletCreditJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as WalletCreditAttributes[];
      const invoice_items = map(
        filter(
          included,
          (incl) => incl.type === 'invoice_items' && incl.attributes.invoice_id === ~~invoice.id,
        ) as InvoiceItemJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as InvoiceItemAttributes[];
      const billings = map(
        filter(
          included,
          (incl) => incl.type === 'billings' && invoice_items.map((i) => i.billing_id).includes(~~incl.id),
        ) as BillingJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as BillingAttributes[];
      const representatives = map(
        filter(included, (incl) => incl.type === 'representatives') as RepresentativeJson[],
        (item) => ({ id: item.id, ...item.attributes }),
      ) as RepresentativeAttributes[];
      const accounts = map(filter(included, (incl) => incl.type === 'accounts') as AccountJson[], (item) => ({
        id: item.id,
        ...item.attributes,
      })) as AccountsAttributes[];
      const registrations = map(
        filter(included, (incl) => incl.type === 'registrations') as RegistrationJson[],
        (item) => ({ id: item.id, ...item.attributes }),
      ) as RegistrationAttributes[];
      const contracts = map(filter(included, (incl) => incl.type === 'contracts') as ContractJson[], (item) => ({
        id: item.id,
        ...item.attributes,
      })) as ContractAttributes[];
      const wallets = map(filter(included, (incl) => incl.type === 'wallets') as WalletJson[], (item) => ({
        id: item.id,
        ...item.attributes,
      })) as WalletAttributes[];
      const registration = find(
        registrations,
        (registration) => ~~registration.id === invoice.registration_id,
      ) as RegistrationAttributes;

      const new_data = {
        invoice_items,
        billings,
        wallet_credits,
        representatives,
        accounts,
        registrations,
        contracts,
        wallets,
        registration,
      };
      setCompanyConfigParams(invoice.company_config)
      setData(new_data);

      dispatch(
        success({
          title: 'Carregar fatura',
          message: 'Fatura carregada com sucesso!',
          autoDismiss: 3,
        }),
      );
      return new_data;
    } catch (err) {
      return data;
      dispatch(
        error({
          message: 'Erro no carregamento da fatura',
        }),
      );
    }
  }, [invoice]);

  const handleInvoiceFormValues = async (initial_data = data) => {
    const use_invoice_items = initial_data.invoice_items.filter((invoice_item) => invoice_item.invoice_id === ~~invoice.id);
    const wallet_credits_attributes = initial_data.wallet_credits.reduce((acc, wallet_credit) => {
      if (wallet_credit.invoice_id === ~~invoice.id) {
        return acc.concat(wallet_credit);
      }
      return acc;
    }, [] as NestedWalletCreditAttributes[]);
    const invoice_items_attributes = use_invoice_items.map((invoice_item) => {
      return {
        ...invoice_item,
      };
    });

    const new_initial_values = {
      invoice_items_attributes,
      wallet_credits_attributes,
      ...(invoice && {
        id: invoice.id,
        anticipation_discount: invoice.anticipation_discount,
        contractual_addition: invoice.contractual_addition,
        expiration_date: invoice.expiration_date,
        fees: invoice.fees,
        installment_addition: invoice.installment_addition,
        installment_number: invoice.installment_number,
        payment_method: invoice.payment_method,
        penalty: invoice.penalty,
        punctuality_expiration_date: invoice.punctuality_expiration_date,
        renegotiation_allowance: invoice.renegotiation_allowance,
        representative_id: invoice.representative_id,
        registration_id: (initial_data.registration && ~~initial_data.registration.id) as number,
        representative_wallet_id: invoice.representative_wallet_id,
        created_at: invoice.created_at
      }),
    };
    const representative_options = [
      ...new Set(
        initial_data.billings.map((billing) => {
          if(billing.billable_type === 'Contract') {
            const contract = initial_data.contracts.find(
              (contract) => ~~contract.id === billing.billable_id,
            ) as ContractAttributes;
            return contract.representative_id;
          } else {
            return invoice.representative_id
          }

        }),
      ),
    ].map((representative_id) => {
      const representative = initial_data.representatives.find(
        (representative) => ~~representative.id === representative_id,
      ) as RepresentativeAttributes;
      return {
        value: ~~representative.id,
        label: representative.representative_name,
      };
    });
    setRepresentativeOptions(representative_options);
    setInitialValues(new_initial_values);
  };
  const initInvoiceForm = React.useCallback(async () => {
    let initial_data = data;
    if (load_resources) {
      initial_data = await fetchInvoice();
    }
    await handleInvoiceFormValues(initial_data);
  }, [initialValues, data, invoice, load_resources]);

  const createInvoiceMethod = React.useCallback(
    async (data: InvoiceFormAttributes) => {
      try {
        await dispatch(CREATE_INVOICE.request({ data }));
        dispatch(
          success({
            title: 'Renegociação concluída!',
            message: 'Nova fatura criada.',
          }),
        );
        await handleFetchInvoices();
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar nova fatura',
          }),
        );
      }
    },
    [handleFetchInvoices, initialValues],
  );

  const updateInvoiceMethod = React.useCallback(
    async (data: InvoiceFormAttributes) => {
      try {
        if (initialValues?.id) {
          await dispatch(UPDATE_INVOICE.request({ data, id: initialValues.id }));
          dispatch(
            success({
              message: 'Fatura atualizada!',
            }),
          );
          await handleFetchInvoices();
        }
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao atualizar fatura',
          }),
        );
      }
    },
    [handleFetchInvoices, initialValues],
  );

  React.useEffect(() => {
    initInvoiceForm();
  }, []);

  const onSubmit = async (data: InvoiceFormAttributes) => {
    const initial_invoice_items_attributes = initialValues?.invoice_items_attributes || [];
    const initial_wallet_credits_attributes = initialValues?.wallet_credits_attributes || [];
    let { invoice_items_attributes, wallet_credits_attributes } = data;
    if (initialValues?.id) {
      invoice_items_attributes = invoiceItemUpdater(
        invoice_items_attributes,
        initial_invoice_items_attributes as NestedInvoiceItemAttributes[],
      );
      wallet_credits_attributes = walletCreditUpdater(
        wallet_credits_attributes || [],
        initial_wallet_credits_attributes as NestedWalletCreditAttributes[],
      );
    }
    const formattedData = omit(
      {
        ...data,
        invoice_items_attributes,
        wallet_credits_attributes,
      },
      ['accumulated_fines', 'id', 'representative_wallet_id'],
    );
    if (initialValues?.id) {
      await updateInvoiceMethod(formattedData);
    } else {
      await createInvoiceMethod(formattedData);
    }
    handleTabChange(null, 'all_invoices');
  };

  if (isEmpty(initialValues)) {
    return <Loading />;
  }

  return (
    <div>
      <span style={{ fontSize: '1.75rem', marginBottom: '2rem', display: 'block' }}>
        {invoice ? `Ajustar fatura ${invoice.code}` : 'Renegociação'}
      </span>
      <InvoiceForm
        billings={data.billings}
        handleTabChange={handleTabChange}
        initialValues={initialValues}
        isFormValid={isFormValid}
        onSubmit={onSubmit}
        representativeOptions={representativeOptions}
        invoice_items_attributes={use_invoice_form_values.invoice_items_attributes}
        invoice_form_values={use_invoice_form_values}
        wallets={data.wallets}
        representatives={data.representatives}
        invoice_company_config={company_config_params}
      />
    </div>
  );
};

export default InvoiceFormContainer;
