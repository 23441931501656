/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import {
  Field,
  change,
  formValueSelector,
  reduxForm,
  reset,
} from 'redux-form';
import {
  TeacherAttributes,
  TeacherFormData,
  DefaultOptionType,
  Paths,
  Role,
  UserFormFields,
  UserOptionsData,
  colors,
  defaultTeacherFormInitialValues,
  validation,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { RootState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { isEmpty } from 'lodash';
import UserForm from './UserForm';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 10%',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
      '& .user-form': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        rowGap: '1rem',
        '& > div': {
          flexBasis: '100%',
        },
        '& #input-nationality_country': {
          flexBasis: '32.6666666%',
          order: 1,
        },
        '& #input-nationality_state': {
          flexBasis: '32.6666666%',
          order: 2,
        },
        '& #input-nationality_city': {
          flexBasis: '32.6666666%',
          order: 3,
        },
        '& #input-document_number': {
          order: 4,
          flexBasis: '24.5%',
        },
        '& #input-name': {
          flexBasis: '24.5%',
          order: 5,
        },
        '& #input-email': {
          flexBasis: '24.5%',
          order: 6,
        },
        '& div[id$="personal_email"]': {
          flexBasis: '24.5%',
          order: 7,
        },
        '& #input-phone': {
          flexBasis: '32.6666666%',
          order: 8,
        },
        '& #input-gender': {
          flexBasis: '32.6666666%',
          order: 9,
        },
        '& #input-birthdate': {
          flexBasis: '32.6666666%',
          order: 10,
        },
        '& .nationality-span': {
          display: 'none',
        },
        '& #input-address_form': {
          order: 11,
        },
        '& div[id$="street"]': {
          flexBasis: '40%',
        },
        '& div[id$="number"]': {
          flexBasis: '24%',
        },
        '& div[id$="complement"]': {
          flexBasis: '35%',
        },
        '& div[id$="neighbourhood"]': {
          flexBasis: '30%',
        },
        '& #input-address_form > div[id$="country_id"]': {
          flexBasis: `20%`,
        },
        '& #input-address_form > div[id$="state_id"]': {
          flexBasis: `25%`,
        },
        '& #input-address_form > div[id$="city_id"]': {
          flexBasis: `22.5%`,
        },
        '& .zipcode': {
          display: 'flex',
          maxWidth: '100%',
          width: '50rem',
          gap: '1rem',
          '& > .search': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25rem',
            cursor: 'pointer',
            marginBottom: '1rem',
            border: 'none',
            gap: '1rem',
            width: 'fit-content',
            background: 'inherit',
            '& > svg': {
              fontSize: '3rem',
              color: colors.darkBlue,
              '&:hover': {
                color: '#5a5a72',
              },
            },
          },
        },
      },  
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'teacherForm';

const TeachersForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: TeacherAttributes;
  setInitialValues: React.Dispatch<React.SetStateAction<Partial<TeacherFormData>>>;
  handleSelectUser: (user_option: UserOptionsData) => Promise<void>
  allowUserUpdate: boolean
}) => {
  const {
    handleSubmit,
    onSubmit,
    initialValues,
    setInitialValues,
    handleSelectUser,
    allowUserUpdate
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const userIdValue = formValues(state, 'user_id') as string;

  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);
  const company_id_to_use = is_above_school_admin ? companyIdValue : companyId;

  const companies_options = getCompanyFilterOptions({
    is_above_school_admin,
    current_company_id: companyId,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_admin && !initialValues?.id;
  const userFormData = [
    'document_number',
    'name',
    'email',
    'additional_data.personal_email',
    'phone',
    'gender',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
  ] as UserFormFields[];
  const handleClearDocumentField = () => {
    if (initialValues.id) {
      setInitialValues({ ...defaultTeacherFormInitialValues(company_id_to_use) });
      dispatch(reset(form_name));
      dispatch(change(form_name, 'document_number', null));
    } else {
      dispatch(change(form_name, 'document_number', null));
    }
  };
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar professor</span>
      <form className={classes.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}
        {companyIdValue ? (
          <>
            <div className='user-form'>
              <UserForm
                data={userFormData}
                form_name={form_name}
                handleClearDocumentField={handleClearDocumentField}
                handleSelectUser={handleSelectUser}
                company_id_to_use={company_id_to_use}
                disabled_document_number={!isEmpty(userIdValue)}
                document_clearable={!isEmpty(userIdValue)}
                disable_all={!allowUserUpdate}
              />
            </div>
            <div className={classes.buttons}>
              <Link to={Paths.TEACHERS_LIST} className='red small'>
                <span> Cancelar </span>
              </Link>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar professor </span>
              </button>
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'teacherForm',
  }),
)(TeachersForm);
