import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field,
  change,
  formValueSelector,
  getFormSyncErrors,
  untouch,
  getFormInitialValues,
  FieldArray,
  WrappedFieldArrayProps,
  FormSection,
} from 'redux-form';
import { RootState } from '../../store/configureStore';
import {
  CountryOptions,
  DefaultOptionType,
  FormFileType,
  NestedUserAlergyAttributes,
  NestedUserDeficiencyAttributes,
  StateOptions,
  UserAttributes,
  UserFormFields,
  UserOptionsData,
  civilStateOptions,
  colors,
  genderOptions,
  validation,
} from '../../utils/constants';
import UploadFileComponent from '../shared/UploadFileComponent';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import DatePickerComponent from '../input/form/datepicker';
import MaskedInputComponent, { PhoneInputComponent } from '../input/form/masked';
import AutoCompleteComponent from '../input/form/autocomplete';
import AddressForm from './AddressForm';
import { FETCH_RACES } from '../../store/races';
import { error, warning } from 'react-notification-system-redux';
import { City, Country, State } from 'country-state-city';
import Loading from '../loading/Loading';
import DocumentNumberField from '../input/form/document_number';
import { isEmailValid } from '../../utils/functions';
import { FETCH_USERS, FetchUsersResponse } from '../../store/users';
import { some } from 'lodash';
import AddButton from '../shared/AddButton';
import { css } from '@emotion/react';
import { FETCH_DEFICIENCIES } from '../../store/deficiencies';
import DeleteButton from '../shared/DeleteButton';
import { FETCH_ALERGIES } from '../../store/alergies';

type UserFormProps = {
  data: UserFormFields[];
  form_name: string;
  disabled_document_number?: boolean;
  handleSelectUser?: (user_option: UserOptionsData) => Promise<void>;
  company_id_to_use?: string;
  handleClearDocumentField?: () => void;
  document_clearable?: boolean;
  handleDocumentChange?: (value: string) => Promise<void>;
  document_label?: string;
  document_placeholder?: string;
  document_number_format_function?: (value: string) => string;
  insertStudentAddress?: () => {
    country_id: string;
    state_id: string;
    city_id: string;
  };
  disable_all?: boolean;
};

interface UserAlergyFormCustomProps extends WrappedFieldArrayProps {
  form_name: string;
  disable: boolean;
}

const renderUserAlergies = (props: UserAlergyFormCustomProps) => {
  const dispatch = useDispatch();
  const [alergiesOptions, setAlergiesOptions] = React.useState<DefaultOptionType[]>([]);
  const [renderReady, setRenderReady] = React.useState(false);
  const { fields, form_name, disable = false } = props;
  const addUserAlergy = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteUserAlergy = React.useCallback(
    (currentUserAlergy: NestedUserAlergyAttributes, user_alergy: string, index: number) => {
      currentUserAlergy.id ? dispatch(change(form_name, `${user_alergy}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );

  const fetchAlergiesMethod = async () => {
    try {
      const alergies = await dispatch(FETCH_ALERGIES.request({}));
      const {
        data: { data },
      } = alergies;
      const formattedData = data.map(({ attributes, id }) => {
        return {
          label: attributes.name,
          value: id,
        };
      });
      setAlergiesOptions(formattedData);
      setRenderReady(true);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de alergia',
        }),
      );
    }
  };
  React.useEffect(() => {
    fetchAlergiesMethod();
  }, []);
  if (!renderReady) {
    return <Loading />;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar alergia ou restrição</span>
        <AddButton disabled={disable} onClick={addUserAlergy} tooltip='Adicionar alergia ou restrição' />
      </div>
      {fields.map((user_alergy, index) => {
        const currentUserAlergy = fields.get(index) as NestedUserAlergyAttributes;
        if (currentUserAlergy._destroy) {
          return null;
        }
        return (
          <div key={user_alergy} style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
            <FormSection name={user_alergy}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
                  <Field
                    disabled={disable}
                    name='alergy_id'
                    label={'Tipo de alergia/restrição'}
                    placeholder={'Tipo'}
                    component={SelectComponent}
                    options={alergiesOptions}
                  />
                  <Field
                    disabled={disable}
                    name='description'
                    label={'Descrição'}
                    placeholder={'Descreva a alergia/restrição'}
                    component={InputComponent}
                  />
                </div>
              </div>
            </FormSection>
            <DeleteButton
              disabled={disable}
              tooltip='Excluir alergia'
              onClick={() => deleteUserAlergy(currentUserAlergy, user_alergy, index)}
            />
          </div>
        );
      })}
    </div>
  );
};

interface UserAlergyFormCustomProps extends WrappedFieldArrayProps {
  form_name: string;
  disable: boolean
}

const renderUserDeficiencies = (props: UserAlergyFormCustomProps) => {
  const dispatch = useDispatch();
  const { fields, form_name, disable = false } = props;
  const [deficienciesOptions, setDeficienciesOptions] = React.useState<DefaultOptionType[]>([]);
  const addUserDeficiency = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteUserDeficiency = React.useCallback(
    (currentUserDeficiency: NestedUserDeficiencyAttributes, user_deficiency: string, index: number) => {
      currentUserDeficiency.id
        ? dispatch(change(form_name, `${user_deficiency}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  const [renderReady, setRenderReady] = React.useState(false);
  const fetchDeficienciesMethod = async () => {
    try {
      const deficiencies = await dispatch(FETCH_DEFICIENCIES.request({}));
      const {
        data: { data },
      } = deficiencies;
      const formattedData = data.map(({ attributes, id }) => {
        return {
          label: attributes.name,
          value: id,
        };
      });
      setDeficienciesOptions(formattedData);
      setRenderReady(true);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro no carregamento das opções de deficiência',
        }),
      );
    }
  };
  React.useEffect(() => {
    fetchDeficienciesMethod();
  }, []);
  if (!renderReady) {
    return <Loading />;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar ponto de atenção</span>
        <AddButton disabled={disable} onClick={addUserDeficiency} tooltip='Adicionar ponto de atenção' />
      </div>
      {fields.map((user_deficiency, index) => {
        const currentUserDeficiency = fields.get(index) as NestedUserDeficiencyAttributes;
        if (currentUserDeficiency._destroy) {
          return null;
        }
        return (
          <div key={user_deficiency} style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
            <FormSection name={user_deficiency}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
                  <Field
                    name='deficiency_id'
                    label={'Tipo'}
                    placeholder={'Tipo'}
                    component={SelectComponent}
                    options={deficienciesOptions}
                    disabled={disable}
                  />
                  <Field
                    name='description'
                    label={'Descrição'}
                    placeholder={'Descreva o ponto de atenção'}
                    component={InputComponent}
                    disabled={disable}
                  />
                </div>
              </div>
            </FormSection>
            <DeleteButton
              disabled={disable}
              tooltip='Excluir ponto de atenção'
              onClick={() => deleteUserDeficiency(currentUserDeficiency, user_deficiency, index)}
            />
          </div>
        );
      })}
    </div>
  );
};

const field_array_css = css`
  display: grid;
  rowGap: 1rem;
  & > span: {
    justify-self: flex-start;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  },
  & > .grid: {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  & > .add: {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    margin-bottom: 1rem;
    border: none;
    gap: 1rem;
    width: fit-content;
    background: inherit;
    & > svg: {
      font-Size: 3rem;
      color: ${colors.darkBlue};
      & :hover: {
        color: #5a5a72
      }
    }
  }
`;

const UserForm = (props: UserFormProps) => {
  const {
    data,
    form_name,
    disabled_document_number,
    handleSelectUser,
    company_id_to_use,
    handleClearDocumentField,
    document_clearable,
    handleDocumentChange,
    document_label = 'CPF *',
    document_placeholder = 'CPF',
    document_number_format_function,
    insertStudentAddress,
    disable_all = false,
  } = props;
  const [countryOptions, setCountryOptions] = React.useState<CountryOptions[]>([]);
  const [stateOptions, setStateOptions] = React.useState<StateOptions[]>([]);
  const [cityOptions, setCityOptions] = React.useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const avatarValue = formValues(state, 'avatar') as FormFileType;
  const dispatch = useDispatch();
  const sync_errors = getFormSyncErrors(form_name)(state);
  const email_has_errors = Object.hasOwn(sync_errors, 'email');
  const cnpjValue: string = formValues(state, 'document_cnpj');
  const cpfValue: string = formValues(state, 'document_cpf');
  const nationalityCountryValue = formValues(state, 'nationality_country');
  const nationalityStateValue = formValues(state, 'nationality_state');
  const initialValues = getFormInitialValues(form_name)(state) as UserAttributes;
  const initial_email = initialValues?.email;

  const is_field_required = (field: UserFormFields) => data.includes(field);
  const clearAvatarField = () => {
    dispatch(change(form_name, 'avatar', null));
    dispatch(untouch(form_name, 'avatar'));
  };
  const [racesOptions, setRaceOptions] = React.useState<DefaultOptionType[]>([]);
  const fetchRacesMethod = async () => {
    try {
      const races = await dispatch(FETCH_RACES.request({}));
      const {
        data: { data },
      } = races;
      const formattedData = data.map(({ attributes, id }) => {
        return {
          label: attributes.name,
          value: id,
        };
      });
      setRaceOptions(formattedData);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de raças',
        }),
      );
    }
  };

  const handleSelectNationalityCountry = (_: React.ChangeEvent<any> | undefined, value: any) => {
    if (value) {
      const states = State.getStatesOfCountry(value['value']).map(({ name, isoCode, countryCode }) => ({
        label: name,
        value: name,
        isoCode,
        countryCode,
      }));
      setStateOptions(states);
    }
  };

  const handleSelectNationalityState = (_: React.ChangeEvent<any> | undefined, value: any) => {
    if (value) {
      const { isoCode, countryCode } = value;
      const cities = City.getCitiesOfState(countryCode, isoCode).map(({ name }) => ({
        label: name,
        value: name,
      }));
      setCityOptions(cities);
    }
  };

  const fetchCountriesMethod = React.useCallback(async () => {
    try {
      const countryList = await Country.getAllCountries().map(({ flag, name, isoCode, phonecode }) => ({
        label: name,
        value: isoCode,
        flag,
        phonecode,
      }));
      countryList && setCountryOptions(countryList);
      return countryList;
    } catch (e) {
      dispatch(error({ message: 'Erro no carregamento dos países' }));
    }
  }, [setCountryOptions]);

  const initUserForm = async () => {
    setLoading(true);
    if (nationalityCountryValue) {
      handleSelectNationalityCountry(undefined, nationalityCountryValue);
    }
    if (nationalityStateValue) {
      handleSelectNationalityState(undefined, nationalityStateValue);
    }
    await fetchCountriesMethod();
    await fetchRacesMethod();
    setLoading(false);
  };

  const checkEmailExistence = React.useCallback(
    async (value: string) => {
      try {
        const users = (await dispatch(
          FETCH_USERS.request({
            params: {
              filters: {
                'q[email_cont]': value,
              },
            },
          }),
        )) as FetchUsersResponse;
        const {
          data: { data },
        } = users;
        if (some(data, (x) => x.attributes.email === value)) {
          dispatch(
            warning({
              title: 'Email em uso',
              message: 'O email inserido já está em uso',
            }),
          );
          dispatch(change('registration-studentstep', 'email', initialValues?.email || ''));
        }
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao verificar se email está em uso',
          }),
        );
      }
    },
    [initialValues],
  );

  const handleEmailChange = (_: React.ChangeEvent<any> | undefined, value: string) => {
    if (value !== initial_email && isEmailValid(value) && company_id_to_use) {
      checkEmailExistence(value);
    }
  };

  React.useEffect(() => {
    initUserForm();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {is_field_required('avatar') && (
        <UploadFileComponent
          fileValue={avatarValue}
          clearFileField={clearAvatarField}
          name={'avatar'}
          fileLabel='Adicionar Foto'
        />
      )}
      {is_field_required('document_number') && (
        <DocumentNumberField
          formName={form_name}
          disabled={Boolean(disabled_document_number) || disable_all}
          handleSelectUser={handleSelectUser}
          company_id={company_id_to_use as string}
          handleClearField={handleClearDocumentField}
          clearable={Boolean(document_clearable)}
          handleDocumentChange={handleDocumentChange}
          label={document_label}
          placeholder={document_placeholder}
          inputFormatFunction={document_number_format_function}
        />
      )}
      {is_field_required('name') && (
        <Field
          name='name'
          label={'Nome do usuario *'}
          placeholder={'Valdemar Oliveira'}
          component={InputComponent}
          upperCase
          validate={[validation.required]}
          disabled={disable_all}
        />
      )}
      {is_field_required('additional_data.social_name') && (
        <Field
          name='additional_data.social_name'
          upperCase
          label={'Nome Social'}
          placeholder={'Nome Social'}
          component={InputComponent}
          disabled={disable_all}
        />
      )}
      {is_field_required('marital_status') && (
        <Field
          name='marital_status'
          component={SelectComponent}
          options={civilStateOptions}
          label={'Estado civil'}
          placeholder={'Solteiro'}
          disabled={disable_all}
        />
      )}
      {is_field_required('gender') && (
        <Field
          component={SelectComponent}
          handleSelectNationalityState
          options={genderOptions}
          label={'Sexo'}
          placeholder={'Masculino'}
          name='gender'
          disabled={disable_all}
        />
      )}
      {is_field_required('birthdate') && (
        <Field
          label={'Nascimento'}
          component={DatePickerComponent}
          name='birthdate'
          placeholder={'11/11/2021'}
          datePickerProps={{
            maxDate: new Date(),
          }}
          disabled={disable_all}
        />
      )}
      {is_field_required('race') && (
        <Field
          name='user_races_attributes[0].race_id'
          component={SelectComponent}
          options={racesOptions}
          label={'Raça/Cor'}
          placeholder={'Mulato'}
          disabled={disable_all}
        />
      )}
      {is_field_required('email') && (
        <Field
          name='email'
          label={'E-mail de cadastro*'}
          placeholder={'valdemaroliveira@exemplo.com.br'}
          onChange={handleEmailChange}
          component={InputComponent}
          validate={[...(form_name === 'registration-studentstep' ? [] : [validation.required]), validation.validEmail]}
          disabled={disable_all}
        />
      )}
      {is_field_required('additional_data.personal_email') && (
        <Field
          name='additional_data.personal_email'
          label={'E-mail pessoal (para funcionários com acesso ao SOMA)'}
          placeholder={'valdemaroliveira@exemplo.com.br'}
          component={InputComponent}
          validate={[validation.validEmail]}
          disabled={email_has_errors || disable_all}
        />
      )}
      {is_field_required('phone') && (
        <Field
          name='phone'
          label={'Telefone'}
          placeholder={'(83)99999-9999'}
          component={PhoneInputComponent}
          initialCountryOptions={countryOptions}
          validate={[validation.validPhone]}
          disabled={disable_all}
        />
      )}
      <span className='nationality-span'>Nacionalidade</span>
      {is_field_required('nationality_country') && (
        <Field
          name='nationality_country'
          label={'Nacionalidade - País'}
          placeholder={'Brasil'}
          component={AutoCompleteComponent}
          onChange={handleSelectNationalityCountry}
          options={countryOptions}
          autoCompleteProps={{
            valueKey: 'label',
            showInputValue: true,
            disabled: disable_all,
          }}
          disabled={disable_all}
        />
      )}
      {is_field_required('nationality_state') && (
        <Field
          name='nationality_state'
          label={'Nacionalidade - Estado'}
          placeholder={'Paraiba'}
          component={AutoCompleteComponent}
          onChange={handleSelectNationalityState}
          options={stateOptions}
          disabled={!stateOptions.length || disable_all}
          autoCompleteProps={{
            showInputValue: true,
            disabled: disable_all,
          }}
        />
      )}
      {is_field_required('nationality_city') && (
        <Field
          name='nationality_city'
          label={'Cidade'}
          placeholder={'João Pessoa'}
          component={AutoCompleteComponent}
          options={cityOptions}
          disabled={disable_all}
          autoCompleteProps={{
            freeSolo: !cityOptions.length,
            showInputValue: true,
            disabled: disable_all,
          }}
        />
      )}
      {is_field_required('document_cnpj') && is_field_required('document_cpf') && (
        <span className='document-span'>Documento (CPF ou CNPJ)</span>
      )}
      {is_field_required('document_cpf') && (
        <Field
          name='document_cpf'
          label={'CPF *'}
          disabled={cnpjValue || disable_all}
          placeholder={'123.456.789-00'}
          maskProps={{
            format: '###.###.###-##',
            mask: '_',
          }}
          component={MaskedInputComponent}
          validate={
            cpfValue || (cpfValue === undefined && cnpjValue === undefined)
              ? [validation.required, validation.validCpf]
              : []
          }
        />
      )}
      {is_field_required('document_cpf') && (
        <Field
          name='document_cnpj'
          label={'CNPJ *'}
          disabled={cpfValue || disable_all}
          placeholder='01.234.567/8900-00'
          maskProps={{
            format: '##.###.###/####-##',
            mask: '_',
          }}
          component={MaskedInputComponent}
          validate={
            cnpjValue || (cpfValue === undefined && cnpjValue === undefined)
              ? [validation.required, validation.validCnpj]
              : []
          }
        />
      )}
      <AddressForm form_name={form_name} insertStudentAddress={insertStudentAddress} disable_all={disable_all} />
      {is_field_required('user_alergies') && (
        <div css={field_array_css} id='user_alergies'>
          <span className='subtitle-one'>Alergias ou Restrições</span>
          <FieldArray
            name='user_alergies_attributes'
            component={renderUserAlergies}
            form_name={form_name}
            disable={disable_all}
          />
        </div>
      )}

      {is_field_required('user_alergies') && is_field_required('user_deficiencies') && <hr id='other_user_data_separator' />}
      {is_field_required('user_deficiencies') && (
        <div css={field_array_css} id='user_deficiencies'>
          <span className='subtitle-one'>Pontos de atenção</span>
          <FieldArray
            name='user_deficiencies_attributes'
            component={renderUserDeficiencies}
            form_name={form_name}
            disable={disable_all}
          />
        </div>
      )}
    </>
  );
};

export default UserForm;
