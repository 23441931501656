/* eslint-disable camelcase */
import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  ClassroomViewAttributes,
  PaginationType,
  Paths,
  periodOptions,
  PeriodTypes,
  Role,
  defaultPagination,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    tableTitle: any;
    button: any;
    filters: any;
    table: any;
  } => ({
    view: {
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    tableTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      background: colors.green,
      padding: '1rem 4rem',
      border: 'none',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '1rem',
      '& > span': {
        marginBottom: '14px',
      },
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
    table: {
      background: colors.lightBlue,
      padding: '2rem',
      borderRadius: '4px',
      display: 'grid',
      '& .subtitle': {
        textAlign: 'start',
        marginBottom: '1rem',
      },
      '& .MuiTableContainer-root': {
        background: 'inherit',
        boxShadow: 'none',
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${colors.grayBlue}`,
        },

        '& th': {
          color: colors.darkGrayBlue,
        },
        '& td': {
          fontSize: '12px',
          color: colors.darkBlue,
          [theme.breakpoints?.down('lg')]: {
            fontSize: '0.75rem',
          },
          '& svg': {
            cursor: 'pointer',
            margin: '0 0.25rem',
          },
        },
        '& td.MuiTableCell-footer ': {
          borderBottom: 'none',
        },
      },
    },
  }),
);

const ClassroomsView = ({
  classrooms,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchClassroomsMethod,
  yearFilterValue,
  setYearFilterValue,
  periodFilterValue,
  setPeriodFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
}: {
  classrooms: ClassroomViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  yearFilterValue: string;
  setYearFilterValue: React.Dispatch<React.SetStateAction<string>>;
  ktwelveFilterValue: string;
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>;
  periodFilterValue: string;
  setPeriodFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchClassroomsMethod: ({
    newPagination,
    period,
    company_filter,
  }: {
    period?: PeriodTypes | string;
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_admin,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_admin ? ['Unidade'] : []), 'Nome', 'Ano', 'Período', 'Série', ''];

  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Turmas</span>
        <button className='green small' onClick={() => history.push(Paths.CLASSROOM_FORM)}>
          <span>Criar turma</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div className={classes.filters}>
          {is_above_school_admin && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchClassroomsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por ano`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: yearFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setYearFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Período'
            small
            options={periodOptions}
            input={{
              value: periodFilterValue,
              onChange: (e: any) => {
                setPeriodFilterValue(e.target.value);
                fetchClassroomsMethod({
                  period: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setPeriodFilterValue('');
              fetchClassroomsMethod({
                period: '',
              });
            }}
          />
          <InputComponent
            placeholder={`Buscar por série`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: ktwelveFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKtwelveFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {classrooms.map((classroom) => {
                const period = periodOptions.find((item) => item.value === classroom.period)?.label;
                return (
                  <TableRow key={classroom.id}>
                    {is_above_school_admin && <TableCell>{classroom.company}</TableCell>}
                    <TableCell>{classroom.name}</TableCell>
                    <TableCell>{classroom.year}</TableCell>
                    <TableCell>{period}</TableCell>
                    <TableCell>{classroom.ktwelve?.name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          history.push(Paths.CLASSROOM_FORM, {
                            classroom_id: classroom.id,
                            company_id: classroom.company_id
                          });
                        }}
                      >
                        <PenIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ClassroomsView;
