/* eslint-disable standard/computed-property-even-spacing */
import React from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { useMediaQuery } from 'react-responsive'

import MenuIcon from '@mui/icons-material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Drawer from '@mui/material/Drawer'
import { makeStyles } from '@mui/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'


import { SIGN_OUT } from '../../store/auth'
import SelectProfile from '../input/SelectProfileInput'
import logo2 from '../../assets/logo2.svg'
import { TransactionEventEnum, colors, menuOptions } from '../../utils/constants'
import { calculateMenuOptions, nameToInitials } from '../../utils/functions'
import { RootState } from '../../store/configureStore'

import SomaSIcon from '../icon/SomaSIcon'
import { CREATE_TRANSACTION } from '../../store/transactions'

const useStyles = makeStyles((theme: any) : {
  sidebar: any,
  logo: any,
  initials: any,
  menu: any,
  drawerList: any,
  select: any,
  drawer: any,
  drawerOpen: any,
  drawerClose: any
} => {
  return {
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      background: colors.darkBlue,
      alignItems: 'center',
      padding: (props: any) => (props.sidebarMinWidth ? 'unset' : '1rem'),
      boxSizing: 'border-box',
      minWidth: (props: any) => props.sidebarMinWidth,
      [theme.breakpoints?.down(1224)]: {
        flexGrow: '1',
        flexBasis: 'unset',
        width: 'auto',
        minWidth: '15rem'
      },
      '& > span': {
        color: 'white',
        marginTop: '0.5rem'
      },
      '& > hr': {
        border: '1px dashed #5a5a72',
        width: '60%',
        margin: '1rem 0'
      },
      '& button': {
        fontWeight: 'bold'
      }
    },
    logo: {
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '2rem auto',
      '& > img': {
        maxWidth: '62.5%',
        margin: 'auto'
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        '& nth-child(1)': {
          fontSize: '1rem'
        },
        '& nth-child(2)': {
          fontSize: '2rem'
        }
      }
    },
    initials: {
      background: 'lightblue',
      borderRadius: '50%',
      display: 'flex',
      width: '5rem',
      height: '5rem',
      '& > span': {
        fontSize: '2.5rem',
        margin: 'auto'
      },
      '& > img': {
        width: '100%',
        height: '100%',
        margin: 'auto',
        borderRadius: '50%',
      }
    },
    menu: {
      listStyle: 'none',
      padding: '0',
      textAlign: 'justify',
      '& ul': {
        listStyle: 'none'
      },
      '& li': {
        color: 'white',
        marginBottom: '0.5rem',
        cursor: 'pointer',
        '& .current': {
          color: '#FACBBF'
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center'
        },
        '& > div:hover': {
          textDecoration: 'underline'
        },
        '& > span:hover': {
          textDecoration: 'underline'
        },
        '& svg': {
          width: '1rem',
          marginRight: '0.25rem'
        }
      },
      ' & span': {
        color: 'inherit',
        fontSize: '0.75rem'
      }
    },
    drawerList: {
      '& a': {
        textDecoration: 'none'
      },
      '& span': {
        color: 'white'
      },
      '& li:hover': {
        background: '#454560'
      }
    },
    select: {
      margin: '0.25rem 0',
      width: '100%',
      '& > div': {
        height: 'fit-content'
      },
      '& span': {
        fontSize: '12px !important'
      }
    },
    drawer: {
      width: (props: any) => props.sidebarWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: (props: any) => props.sidebarWidth,
      background: 'inherit',
      transition: theme.transitions?.create('width', {
        easing: theme.transitions?.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      background: 'inherit',
      transition: theme.transitions?.create('width', {
        easing: theme.transitions?.easing.sharp,
        duration: theme.transitions?.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: '4rem',
      [theme.breakpoints?.up('sm')]: {
        width: typeof theme?.spacing === 'function' && theme?.spacing(9) + 1
      }
    }
  }
})

const MiniDrawer = ({ open, setOpen } : { open: boolean, setOpen: any }) => {
  const location = useLocation()
  const classes = useStyles({})
  const dispatch = useDispatch()
  const {
    auth: { profile, company, user: { user_id } },
    account: { companies }
  } = useSelector((state: RootState) => state)
  const createUserEvent = async (menu_label: string) => {
    await dispatch(CREATE_TRANSACTION.request({
      data: {
        event: TransactionEventEnum.USER_EVENT,
        transactable_id: user_id,
        transactable_type: 'User',
        event_date: new Date().toISOString(),
        description: `Usuário clicou na opção do menu: ${menu_label}`
      }
    }))
  }

  return (
    <Drawer
      open={open}
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <List className={classes.drawerList}>
        <ListItem
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpen((current: boolean) => !current)
          }}
        >
          <ListItemIcon>
            <SomaSIcon />
          </ListItemIcon>
        </ListItem>
        {calculateMenuOptions(menuOptions, profile, companies, company).map(({ label, icon: Icon, path }) => {
            const IconTextColor = location?.pathname === path ? '#FACBBF' : 'white'
            return (
              <Link onClick={() => createUserEvent(label)} to={path} key={label}>
                <ListItem>
                  <ListItemIcon>
                    {' '}
                    <Icon style={{ fill: IconTextColor, color: IconTextColor }} />{' '}
                  </ListItemIcon>
                  <ListItemText style={{ color: IconTextColor }}>{label}</ListItemText>
                </ListItem>
              </Link>
            )
          })}
        <ListItem
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(SIGN_OUT.request())
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  )
}


const MenuComponent = () => {
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    auth: { profile, company, user: { id: user_id } },
    account: { companies }
  } = useSelector((state: RootState) => state)
  const createUserEvent = async (menu_label: string) => {
    await dispatch(CREATE_TRANSACTION.request({
      data: {
        event: TransactionEventEnum.USER_EVENT,
        transactable_id: user_id,
        transactable_type: 'User',
        event_date: new Date().toISOString(),
        description: `Usuário clicou na opção do menu: ${menu_label}`
      }
    }))
  }
  
  return (
    <ul className={classes.menu}>
      {calculateMenuOptions(menuOptions, profile, companies, company).map(({ key, label, icon: Icon, path, children }) => {
          return (
            <li key={key}>
              <div
                className={location?.pathname === path ? 'current' : ''}
                onClick={() => {
                  createUserEvent(label)
                  history.push(path)
                }}
              >
                <Icon />
                <span>{label}</span>
              </div>
              {children && children.length && (
                <ul>
                  {children
                    .filter((item) => {
                      return item.profiles && item.profiles.includes(profile.label)
                    })
                    .map((child) => {
                      return (
                        <li
                          onClick={() => history.push(child.path)}
                          key={child.key}
                          className={location?.pathname === path ? 'current' : ''}
                        >
                          <span>{child.label}</span>
                        </li>
                      )
                    })}
                </ul>
              )}
            </li>
          )
        })}
    </ul>
  )
}

const Sidebar = ({ closeDrawer, dashboard } : { closeDrawer?: null | (() => void), dashboard?: boolean }) => {
  const dispatch = useDispatch()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const {
    auth: { user, company },
    account: { companies }
  } = useSelector((state: RootState) => state)
  const [open, setOpen] = React.useState(false)
  const classes = useStyles({
    sidebarWidth: dashboard ? '10rem' : '15rem',
    sidebarMinWidth: dashboard ? (open ? '14rem' : '4rem') : '15rem'
  })
  const companyInfo = companies.find((item: any) => item.id === company)
  return (
    <div className={classes.sidebar}>
      {dashboard ? (
        <MiniDrawer open={open} setOpen={setOpen} />
      ) : (
            <>
                <div className={classes.logo}>
                  <img src={logo2} alt='soma' />
                  {isTabletOrMobile && (
                    <div
                      onClick={() => {
                        if (closeDrawer) {
                          closeDrawer()
                        }
                      }}
                    >
                      <ArrowBackIosIcon />
                      <MenuIcon />
                    </div>
                  )}
                </div>
                <div className={classes.initials}>
                  {companyInfo?.logo_url ? (
                    <img src={companyInfo.logo_url} alt='company_logo' />
                  ) : user && user.name ? (
                    <span> {nameToInitials(user?.name)} </span>
                  ) : null}
                </div>
                <span> {companyInfo?.name} </span>
                {isTabletOrMobile && (
                  <div className={classes.select}>
                    <SelectProfile />
                  </div>
                )}
                <hr />
                <MenuComponent />
                {isTabletOrMobile && (
                  <button
                    className='white'
                    onClick={() => {
                      dispatch(SIGN_OUT.request())
                    }}
                  >
                        LOGOUT
                  </button>
                )}
            </>
      )}
    </div>
  )
}

export default Sidebar
