/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, RegistrationProductFormAttributes, RequestParams, UpdateRegistrationProductFormAttributes } from '../../utils/constants'
import { ProductJson, ProductPeriodJson } from '../products'
import { RegistrationProductSubsidiesJson } from '../registrations'
import { PaymentCollectionJson, PaymentOptionJson } from '../payment_options'
import { SubsidyJson } from '../subsidies'
import { RepresentativeJson, RepresentativeProductJson } from '../representatives'
import { BillingJson } from '../billings'
import { InvoiceJson } from '../invoices'
import { ContractJson } from '../contracts'

export interface RegistrationProductAttrs {
  active: boolean
  code: string
  created_at: string
  expiration_day: number
  payment_option_id: number
  product_id: number
  provision_months: number[]
  punctuality_discount_portions: number[]
  registration_id: number
  starts_at: string
  year: number
  payment_option_name: string
  kind: string
  product_name: string,
  invoices_through_product: boolean
  notification_email: string
}

export type RegistrationProductJson = JsonFormat<'registration_products', RegistrationProductAttrs>
export type FetchRegistrationProductResponse = JsonResponseFormat<RegistrationProductJson, (
  ProductJson|ProductPeriodJson|RegistrationProductSubsidiesJson|PaymentOptionJson|SubsidyJson
  |RegistrationProductJson|RepresentativeProductJson|RepresentativeJson|BillingJson|
  PaymentCollectionJson|InvoiceJson|ContractJson)[]>

export interface CreateRegistrationProductPayload {
  data: RegistrationProductFormAttributes
}
export interface FetchRegistrationProductPayload {
  id: string
  params?: RequestParams
}
export interface UpdateRegistrationProductPayload {
  id: string
  data: Partial<UpdateRegistrationProductFormAttributes>
}
export interface DeleteRegistrationProductPayload {
  id: string|number;
}

export interface GenerateRegistrationProductBillingPayload {
  id: string;
}

export const CREATE_REGISTRATION_PRODUCT = createPromiseAction('registration_product: CREATE_REGISTRATION_PRODUCT')<CreateRegistrationProductPayload, FetchRegistrationProductPayload, any>()
export const FETCH_REGISTRATION_PRODUCT = createPromiseAction('registration_product: FETCH_REGISTRATION_PRODUCT')<FetchRegistrationProductPayload, FetchRegistrationProductResponse, any>()
export const UPDATE_REGISTRATION_PRODUCT = createPromiseAction('registration_product: UPDATE_REGISTRATION_PRODUCT')<UpdateRegistrationProductPayload, FetchRegistrationProductResponse, any>()
export const DELETE_REGISTRATION_PRODUCT = createPromiseAction('registration_product: DELETE_REGISTRATION_PRODUCT')<DeleteRegistrationProductPayload, AxiosResponse, any>()
export const GENERATE_REGISTRATION_PRODUCT_BILLINGS = createPromiseAction('registration_product: GENERATE_REGISTRATION_PRODUCT_BILLINGS')<GenerateRegistrationProductBillingPayload, AxiosResponse, any>()
