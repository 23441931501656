import { get, isEmpty, map, orderBy } from "lodash";
import { OperationAttributes, OperationKindEnum, OperationOperableType } from "./constants";

export const operation_simulation = (operation: Partial<OperationAttributes>) => {
  let result = ''
  if(isEmpty(operation)){
    return result
  }
  const this_result = map(orderBy(get(operation, 'children_attributes'), 'order'), item => operation_simulation(item))
  const kind = operation?.kind
  const maxPreffix = (operation?.number_input && operation.number_input > 0) ? `MAX${operation.number_input}` : ''
  if(kind === OperationKindEnum.ADDITION){
    result = `${maxPreffix}(${this_result.join(' + ')})`
  } else if(kind === OperationKindEnum.SUBTRACTION) {
    result = `${maxPreffix}(${this_result.join(' - ')})`
  } else if(kind === OperationKindEnum.MULTIPLICATION) {
    result = `${maxPreffix}(${this_result.join(' * ')})`
  } else if(kind === OperationKindEnum.DIVISION){
    result = `${maxPreffix}(${this_result.join(' / ')})`
  } else if (kind === OperationKindEnum.MAX_VALUE) {
    result = `MAX(${this_result})`
  } else if (kind === OperationKindEnum.MIN_VALUE) {
    result = `MIN(${this_result})`
  } else if (kind === OperationKindEnum.NUMBER_INPUT){
    result = operation.label || (operation.number_input ? operation.number_input.toString() : 'N')
  } else if (kind === OperationKindEnum.OPERABLE_INPUT){
    const operable_type = OperationOperableType.EXAM_PLACEMENT ? 'A' : 'F'
    result = operation.label || operable_type
  }
  return result
}