/* eslint-disable camelcase */
import React from 'react';
import { filter, find, head, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';

import {
  colors,
  onConfirm,
  productTypeOptions,
  RegistrationClosureAttributes,
  SubsidyAttributes,
  RegistrationProductSubsidyAttributes,
  CurrentRegistrationData,
  RegistrationProductAttributes,
  PaymentDataItems,
  ContractStatusEnum,
  PaymentSimulationPaymentInfo,
  PaymentSimulation,
  ProductAttributes,
  ContractAttributes,
  PaymentSimulationBillings,
  PaymentCollectionAttributes,
  BillingAttributes,
  PaymentOptionAttributes,
  InvoiceAttributes,
  InvoiceStatusEnum,
} from '../../utils/constants';
import { DeleteItemModal, IconModal } from '../modal/Modal';
import BillingTable from './BillingTable';
import Loading from '../loading/Loading';
import { Percent, DoDisturbAlt, HourglassTop, MonetizationOn, Receipt } from '@mui/icons-material';
import CloseRegistrationProduct from '../shared/CloseRegistrationProduct';
import { RootState } from '../../store/configureStore';
import { format, parse } from 'date-fns';
import ContractIcon from '../icon/ContractIcon';
import { checkPaymentOptionAndCollections, evaluate_permissions, newShade } from '../../utils/functions';
import RegistrationProductSubsidiesTable from './RegistrationProductSubsidiesTable';
import { ContractsTableBody } from './ContractsTable';
import EditRegistrationProduct from '../shared/EditRegistrationProduct';
import TrashIcon from '../icon/TrashIcon';
import { ContractJson, FETCH_CONTRACTS } from '../../store/contracts';
import { error, success, warning } from 'react-notification-system-redux';
import { paymentSimulationFactory } from '../../utils/paymentSimulation';
import { FETCH_REGISTRATION_PRODUCT, GENERATE_REGISTRATION_PRODUCT_BILLINGS } from '../../store/registration_products';
import { RegistrationProductSubsidiesJson } from '../../store/registrations';
import { SubsidyJson } from '../../store/subsidies';
import { ProductJson } from '../../store/products';
import { PaymentCollectionJson, PaymentOptionJson } from '../../store/payment_options';
import { BillingJson } from '../../store/billings';
import { InvoiceJson } from '../../store/invoices';

const useStyles = makeStyles(() => ({
  table: {
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& tfoot': {
      background: 'white',
      '& td': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      '& td:last-of-type': {
        color: colors.blue,
      },
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer',
        },
      },
      '& tbody > tr': {
        background: colors.lightBlue,
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
    },
  },
  productContractsTable: {
    '& .MuiTableContainer-root': {
      '& .products-contract-table-body > tr': {
        background: 'white',
      },
      '& .products-contract-table-body > tr:not(.highlighted-contract) > td': {
        color: newShade(colors.darkGrayBlue, 3),
      },
    },
    '& .product-contract-title': {
      fontWeight: 'bold',
    },
    '& .representative-name': {
      marginLeft: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      '& .generate-button-span': {
        cursor: 'pointer',
      },
      '& button:disabled, button[disabled]': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .representative-name:last-of-type': {
      marginBottom: '1rem',
    },
  },
}));

const columns = ['Código', 'Opç Pagamento', 'Tipo', 'Ano', 'Nome', 'Início das aulas'];

type ParsedResult = {
  tableValue: string;
  initialValue: string;
  discountValue: string;
  total: string;
};

const RegistrationProductSubsidiesComponent = (args: {
  registration_product_id: string;
  selectProduct: (id: number) => void;
  fetchRegistrationProductPaymentData: (registration_product_id: string) => Promise<
    | {
        payment_option: PaymentOptionAttributes;
        payment_collections: PaymentCollectionAttributes[];
        billings: BillingAttributes[];
        contracts: ContractAttributes[];
      }
    | {
        payment_option: null;
        payment_collections: PaymentCollectionAttributes[];
        billings: BillingAttributes[];
        contracts: ContractAttributes[];
      }
  >;
  expandRow: (registration_product_id: number, expandMethod: React.Dispatch<React.SetStateAction<number[]>>) => void;
  setExpandedProductSubsidiesRow: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const {
    registration_product_id,
    selectProduct,
    fetchRegistrationProductPaymentData,
    setExpandedProductSubsidiesRow,
    expandRow,
  } = args;

  const [loading, setLoading] = React.useState(true);
  const [subsidies, setSubsidies] = React.useState<SubsidyAttributes[]>([]);
  const [registration_product_subsidies, setRegistrationProductSubsidies] = React.useState<
    RegistrationProductSubsidyAttributes[]
  >([]);
  const [product, setProduct] = React.useState<ProductAttributes | null>(null);
  const dispatch = useDispatch();

  const verifyData = React.useCallback(async () => {
    const result = await fetchRegistrationProductPaymentData(registration_product_id);
    const { billings, contracts } = result;
    if (isEmpty(contracts) && isEmpty(billings)) {
      expandRow(~~registration_product_id, setExpandedProductSubsidiesRow);
      selectProduct(~~registration_product_id);
    }
  }, [registration_product_id]);

  const getRegistrationProductSubsidies = React.useCallback(async () => {
    await verifyData();
    setLoading(true);
    const response = await dispatch(
      FETCH_REGISTRATION_PRODUCT.request({
        id: registration_product_id,
        params: {
          filters: {
            include: ['product', 'registration_product_subsidies.subsidy'].join(','),
          },
        },
      }),
    );
    const {
      data: { included },
    } = response;
    const includedRegistrationProductSubsidies = map(
      filter(included, (item) => item.type === 'registration_product_subsidies') as RegistrationProductSubsidiesJson[],
      (res) => ({ id: res.id, ...res.attributes }),
    );
    const includeSubsidies = map(filter(included, (item) => item.type === 'subsidies') as SubsidyJson[], (res) => ({
      id: res.id,
      ...res.attributes,
    }));
    const product = find(included, (item) => item.type === 'products') as ProductJson;
    setRegistrationProductSubsidies(includedRegistrationProductSubsidies);
    setSubsidies(includeSubsidies);
    setProduct({
      id: product.id,
      ...product.attributes,
    });
    setLoading(false);
  }, [registration_product_id]);

  React.useEffect(() => {
    getRegistrationProductSubsidies();
  }, []);
  if (loading) {
    return <Loading />;
  }

  if (isEmpty(registration_product_subsidies)) {
    return <span>Não há descontos para esse produto</span>;
  }

  return (
    <RegistrationProductSubsidiesTable
      product={product as ProductAttributes}
      registration_product_subsidies={registration_product_subsidies}
      subsidies={subsidies}
    />
  );
};

const RegistrationProductContracts = (args: {
  registration_product: RegistrationProductAttributes;
  fetchContracts: (registration_product_id: string) => Promise<ContractAttributes[]>;
}) => {
  const { registration_product, fetchContracts } = args;
  const [contracts, setContracts] = React.useState<ContractAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const initContracts = React.useCallback(async () => {
    setLoading(true);
    const result = await fetchContracts(registration_product.id);
    setContracts(result);
    setLoading(false);
  }, [registration_product]);
  React.useEffect(() => {
    initContracts();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <ContractsTableBody
      contracts={contracts}
      product_id={registration_product.product_id.toString()}
      refreshResources={initContracts}
      registration_id={registration_product.registration_id.toString()}
      registration_product_id={registration_product.id}
    />
  );
};

const BillingTableContainer = (args: {
  payment_simulation: PaymentSimulation;
  registration_product: RegistrationProductAttributes;
  closure: RegistrationClosureAttributes;
  fetchContracts: (registration_product_id: string) => Promise<ContractAttributes[]>;
}) => {
  const { registration_product, payment_simulation, closure, fetchContracts } = args;
  const [paymentInfo, setPaymentInfo] = React.useState<PaymentSimulationPaymentInfo[]>([]);
  const [parsedResult, setParsedResult] = React.useState<ParsedResult | null>(null);
  const [loading, setLoading] = React.useState(true);

  const getContracts = React.useCallback(async () => {
    setLoading(true);
    const contracts = await fetchContracts(registration_product.id);
    const relatedContracts = contracts.sort((a, b) => {
      if (a.status === ContractStatusEnum.SIGNED && b.status !== ContractStatusEnum.SIGNED) {
        return -1;
      } else if (a.status !== ContractStatusEnum.SIGNED && b.status === ContractStatusEnum.SIGNED) {
        return 1;
      } else if (a.status === ContractStatusEnum.SIGNED && b.status === ContractStatusEnum.SIGNED) {
        const da = new Date(a.sent_at).getTime();
        const db = new Date(b.sent_at).getTime();
        return db - da;
      } else {
        const da = new Date(a.created_at).getTime();
        const db = new Date(b.created_at).getTime();
        return db - da;
      }
    });
    const payment_simulation_registration_product =
      closure && relatedContracts && !isEmpty(relatedContracts) && head(relatedContracts)?.payment_simulation
        ? (head(relatedContracts)?.payment_simulation as PaymentDataItems)
        : (payment_simulation.selected_items.find((item) => {
            return item.registration_product_id === ~~registration_product.id;
          }) as PaymentDataItems);
    const calculated_payment_simulation = paymentSimulationFactory({
      registration_product: registration_product,
      payment: payment_simulation_registration_product.payment,
      closure,
    });
    setPaymentInfo(calculated_payment_simulation.paymentInfo);
    setParsedResult(calculated_payment_simulation.parsedResult);
    setLoading(false);
  }, [registration_product]);
  React.useEffect(() => {
    getContracts();
  }, []);
  if (loading) {
    return <Loading />;
  }
  if (isEmpty(paymentInfo) || isEmpty(parsedResult)) {
    return <span>Não há simulação de pagamento para esse produto</span>;
  }
  return <BillingTable paymentInfo={paymentInfo} result={parsedResult as ParsedResult} />;
};

const ClosureRow = ({
  closure,
  active,
  onConfirm,
  fetchRegistrationProducts,
  product_year,
  provision_months,
}: {
  active: boolean;
  closure: RegistrationClosureAttributes;
  fetchRegistrationProducts: () => Promise<void>;
  onConfirm: onConfirm;
  product_year: number;
  provision_months: number[];
}) => {
  if (isEmpty(closure)) {
    return null;
  }
  const closureDate = !!closure && format(new Date(closure?.closure_date), 'dd/MM/yyyy');
  const firstRow = active && new Date(closure.closure_date) > new Date() ? 'Cancelamento Agendado' : 'Cancelamento';
  const canDeleteClosure = active;
  return (
    <Table className='simulation' aria-label='simulation-table' size='small'>
      <TableBody>
        <TableRow style={{ backgroundColor: colors.grayBlue }}>
          <TableCell colSpan={1}>{firstRow}</TableCell>
          <TableCell>Status: {closure.status} </TableCell>
          <TableCell>Razão: {closure.reason}</TableCell>
          <TableCell>Data: {closureDate}</TableCell>
          <TableCell colSpan={2}>Detalhes: {closure.details}</TableCell>
          <TableCell align='center' style={{ paddingRight: '2cqw' }} colSpan={2}>
            {!isEmpty(closure) && (
              <CloseRegistrationProduct
                fetchRegistrationProducts={fetchRegistrationProducts}
                initialValues={closure}
                product_year={product_year}
                provision_months={provision_months}
                registration_product_id={closure.registration_product_id.toString()}
                active={active}
              />
            )}
            {canDeleteClosure && (
              <DeleteItemModal
                tooltipText='Excluir cancelamento'
                message={`Deseja excluir o cancelamento do produto ${name}?`}
                title='Excluir cancelamento do produto'
                onConfirm={onConfirm}
              />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ProductTableActions = ({
  active_product,
  closure,
  closureInitialValues,
  deleteRegistrationProduct,
  disable_actions,
  expandedClosureRow,
  expandedProductContractsRow,
  expandedRows,
  expandRow,
  fetchRegistrationProducts,
  name,
  product_year,
  profile,
  provision_months,
  registration_product_id,
  setExpandedClosureRows,
  setExpandedProductContractsRow,
  setExpandedProductSubsidiesRow,
  setExpandedRows,
  starts_at,
  verifyIfCanDeleteProductContracts,
  generateBillings,
  verifyIfCanGenerateBillings,
  invoices_through_product,
  payment_simulation,
  notification_email
}: {
  active_product: boolean;
  payment_simulation: PaymentDataItems;
  invoices_through_product: boolean;
  closure: RegistrationClosureAttributes;
  closureInitialValues: RegistrationClosureAttributes;
  deleteRegistrationProduct: ({ setLoading, handleClose }: any, registration_product_id: string) => Promise<void>;
  disable_actions: boolean;
  expandedClosureRow: number[];
  expandedProductContractsRow: number[];
  expandedRows: number[];
  expandRow: (registration_product_id: number, expandMethod: React.Dispatch<React.SetStateAction<number[]>>) => void;
  fetchRegistrationProducts: () => Promise<void>;
  name: string;
  product_year: number;
  profile: any;
  provision_months: number[];
  registration_product_id: string;
  notification_email: string
  setExpandedClosureRows: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedProductContractsRow: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedProductSubsidiesRow: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedRows: React.Dispatch<React.SetStateAction<number[]>>;
  starts_at: string;
  verifyIfCanDeleteProductContracts: (args: {
    handleClose: () => void;
    registration_product_id: string;
  }) => Promise<void>;
  generateBillings: ({
    registration_product_id,
  }: {
    registration_product_id: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>;
  verifyIfCanGenerateBillings: (args: {
    handleClose: () => void;
    registration_product_id: string;
    payment_simulation_billings: PaymentSimulationBillings[];
  }) => Promise<void>;
}) => {
  const handleDiscountButton = async () => {
    expandRow(~~registration_product_id, setExpandedProductSubsidiesRow);
  };
  const ClosureIcon = active_product ? HourglassTop : DoDisturbAlt;
  return (
    <>
      {!isEmpty(closure) && (
        <Tooltip title={`${expandedClosureRow.includes(~~registration_product_id) ? 'Ocultar' : 'Ver'} cancelamento`}>
          <IconButton
            onClick={() => expandRow(~~registration_product_id, setExpandedClosureRows)}
            disabled={disable_actions}
          >
            <ClosureIcon style={{ color: colors.lightRed }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={`${expandedRows.includes(~~registration_product_id) ? 'Ocultar' : 'Ver'} simulação de pagamento`}>
        <IconButton onClick={() => expandRow(~~registration_product_id, setExpandedRows)} disabled={disable_actions}>
          <MonetizationOn style={{ color: colors.green }} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={`${expandedProductContractsRow.includes(~~registration_product_id) ? 'Ocultar' : 'Ver'} contratos`}
      >
        <IconButton
          onClick={() => expandRow(~~registration_product_id, setExpandedProductContractsRow)}
          disabled={disable_actions || invoices_through_product}
        >
          <ContractIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Descontos'>
        <IconButton onClick={handleDiscountButton} disabled={disable_actions}>
          <Percent />
        </IconButton>
      </Tooltip>
      {evaluate_permissions.is_school_admin_or_above(profile.role) && !disable_actions && (
        <>
          <IconModal
            tooltipText='Remover produto da matricula'
            title='Remover produto da matricula'
            message={`Deseja remover o produto ${name} desta matricula?`}
            onConfirm={async (props) => deleteRegistrationProduct(props, registration_product_id as string)}
            icon={TrashIcon}
            iconProps={{ style: { color: colors.lightRed, cursor: 'pointer' } }}
            onOpen={async (args) => verifyIfCanDeleteProductContracts({ ...args, registration_product_id })}
          />
        </>
      )}
      {isEmpty(closureInitialValues) &&
        evaluate_permissions.is_above_school_admin(profile.role) &&
        !disable_actions && (
          <CloseRegistrationProduct
            provision_months={provision_months}
            product_year={product_year}
            active={active_product}
            initialValues={closureInitialValues}
            registration_product_id={registration_product_id as string}
            fetchRegistrationProducts={fetchRegistrationProducts}
          />
        )}
      {invoices_through_product && active_product && (
        <IconModal
          icon={Receipt}
          onConfirm={(props) => generateBillings({ registration_product_id: registration_product_id, ...props })}
          tooltipText='Gerar Faturas'
          title='Gerar faturas'
          message='Deseja gerar faturas para esse produto?'
          onOpen={(args) =>
            verifyIfCanGenerateBillings({
              ...args,
              registration_product_id,
              payment_simulation_billings: payment_simulation.payment.billings,
            })
          }
          disabled={disable_actions}
        />
      )}
      <EditRegistrationProduct
        fetchRegistrationProducts={fetchRegistrationProducts}
        initialValues={{ starts_at, id: registration_product_id, notification_email }}
      />
    </>
  );
};

const ProductStepTableBody = ({
  deleteRegistrationClosureMethod,
  deleteRegistrationProduct,
  expandedClosureRow,
  expandedProductContractsRow,
  expandedRows,
  fetchRegistrationProducts,
  registration,
  selectProduct,
  setExpandedClosureRows,
  setExpandedProductContractsRow,
  setExpandedRows,
  registrationProductsData,
  closuresData,
}: {
  registrationProductsData: RegistrationProductAttributes[];
  closuresData: RegistrationClosureAttributes[];
  deleteRegistrationClosureMethod: (id: string) => Promise<void>;
  deleteRegistrationProduct: ({ setLoading, handleClose }: any, registration_product_id: string) => Promise<void>;
  expandedClosureRow: number[];
  expandedProductContractsRow: number[];
  expandedRows: number[];
  fetchRegistrationProducts: () => Promise<void>;
  registration: CurrentRegistrationData;
  selectProduct: (id: number) => void;
  setExpandedClosureRows: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedProductContractsRow: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedRows: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const dispatch = useDispatch();

  const {
    auth: { profile },
    ui: {
      loading: { open },
    },
  } = useSelector((state: RootState) => state);

  const [expandedProductSubsidiesRow, setExpandedProductSubsidiesRow] = React.useState<number[]>([]);
  const fetchRegistrationProductData = React.useCallback(async (registration_product_id: string, include: string) => {
    const response = await dispatch(
      FETCH_REGISTRATION_PRODUCT.request({
        id: registration_product_id,
        params: {
          filters: {
            include,
          },
        },
      }),
    );
    return response;
  }, []);
  const fetchRegistrationProductPaymentData = React.useCallback(async (registration_product_id: string) => {
    try {
      const response = await fetchRegistrationProductData(
        registration_product_id,
        [
          'payment_option.payment_collections',
          'billings.invoice_items.invoice',
          'representative_products.contracts',
        ].join(','),
      );
      const {
        data: { included },
      } = response;
      const includedPaymentCollections = map(
        filter(included, (item) => item.type === 'payment_collections') as PaymentCollectionJson[],
        (res) => ({ id: res.id, ...res.attributes }),
      ) as PaymentCollectionAttributes[];
      const includedPaymentOption = find(included, (item) => item.type === 'payment_options') as PaymentOptionJson;
      const includedBillings = map(
        filter(
          included,
          (item) => item.type === 'billings' && item.attributes.billable_type === 'RegistrationProduct',
        ) as BillingJson[],
        (res) => ({ id: res.id, ...res.attributes }),
      ) as BillingAttributes[];
      const includedContracts = map(filter(included, (item) => item.type === 'contracts') as ContractJson[], (res) => ({
        id: res.id,
        ...res.attributes,
      })) as ContractAttributes[];
      const includedInvoices= map(
        filter(
          included,
          (item) => item.type === 'invoices',
        ) as InvoiceJson[],
        (res) => ({ id: res.id, ...res.attributes }),
      ) as InvoiceAttributes[];
      return {
        payment_option: {
          id: includedPaymentOption.id,
          ...includedPaymentOption.attributes,
        } as PaymentOptionAttributes,
        payment_collections: includedPaymentCollections,
        billings: includedBillings,
        contracts: includedContracts,
        invoices: includedInvoices
      };
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao obter informações da opção de pagamento do produto',
        }),
      );
      return {
        payment_option: null,
        payment_collections: [] as PaymentCollectionAttributes[],
        billings: [] as BillingAttributes[],
        contracts: [] as ContractAttributes[],
        invoices: [] as InvoiceAttributes[]
      };
    }
  }, []);
  const fetchContractMethod = React.useCallback(async (registration_product_id: string) => {
    const response = await dispatch(
      FETCH_CONTRACTS.request({
        params: {
          filters: {
            'q[registration_product_id_eq]': registration_product_id,
          },
        },
      }),
    );
    return response;
  }, []);

  const verifyIfCanGenerateBillings = React.useCallback(
    async (args: {
      handleClose: () => void;
      registration_product_id: string;
      payment_simulation_billings: PaymentSimulationBillings[];
    }) => {
      const { handleClose, registration_product_id, payment_simulation_billings } = args;
      try {
        const result = await fetchRegistrationProductPaymentData(registration_product_id);
        const { payment_option, payment_collections, billings } = result;
        if (!payment_option) {
          dispatch(
            error({
              message: 'Erro ao obter informações da opção de pagamento do contrato',
            }),
          );
          handleClose();
        } else if (!isEmpty(billings)) {
          dispatch(
            warning({
              message: 'Não é possível gerar faturas para esse contrato pois já foram geradas anteriormente',
            }),
          );
          handleClose();
        } else if (
          !checkPaymentOptionAndCollections({ payment_option, payment_collections, payment_simulation_billings })
        ) {
          dispatch(
            error({
              message: 'Configurações da opção pagamento não estão adequadas para a geração das faturas desse contrato',
            }),
          );
          handleClose();
        }
      } catch (error) {
        handleClose();
      }
    },
    [],
  );

  const fetchContracts = React.useCallback(async (registration_product_id: string) => {
    const loadProductContracts = async () => {
      const contracts = await fetchContractMethod(registration_product_id);
      const {
        data: { data },
      } = contracts;
      return data.map((item) => ({ id: item.id, ...item.attributes })) as ContractAttributes[];
    };
    try {
      return loadProductContracts();
    } catch (error) {
      return [];
    }
  }, []);

  const verifyIfCanDeleteProductContracts = React.useCallback(
    async (args: { handleClose: () => void; registration_product_id: string }) => {
      const { handleClose, registration_product_id } = args;
      const result = await fetchRegistrationProductPaymentData(registration_product_id);
      const { contracts, invoices } = result;
      if (contracts.length > 0) {
        dispatch(
          warning({
            message: 'Não é possível excluir produto pois há contratos associados para este produto',
          }),
        );
        handleClose();
      } else if (invoices.length > 0 && invoices.some(i => i.status === InvoiceStatusEnum.FINISHED)) {
        dispatch(
          warning({
            message: 'Não é possível excluir o produto pois há faturas pagas para este produto',
          }),
        );
        handleClose();
      }
    },
    [],
  );
  const generateBillings = React.useCallback(
    async ({
      registration_product_id,
      setLoading,
      handleClose,
    }: {
      registration_product_id: string;
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      handleClose: () => void;
    }) => {
      setLoading(true);
      try {
        await dispatch(
          GENERATE_REGISTRATION_PRODUCT_BILLINGS.request({
            id: registration_product_id,
          }),
        );
        dispatch(
          success({
            title: 'Geração de faturas',
            message: 'Faturas geradas com sucesso!',
            autoDismiss: 3,
          }),
        );
        fetchRegistrationProducts();
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro na geração de faturas',
          }),
        );
      }
    },
    [],
  );

  const expandRow = React.useCallback(
    (registration_product_id: number, expandMethod: React.Dispatch<React.SetStateAction<number[]>>) => {
      expandMethod((current) => {
        if (current.includes(registration_product_id)) {
          return current.filter((item) => item !== registration_product_id);
        } else {
          return current.concat(registration_product_id);
        }
      });
    },
    [setExpandedRows, setExpandedClosureRows, setExpandedProductContractsRow, setExpandedProductSubsidiesRow],
  );

  return (
    <TableBody>
      {registrationProductsData.map((row) => {
        const { code, payment_option_name, kind, year, product_name, starts_at } = row;
        const closure = closuresData.find(
          (closure) => closure.registration_product_id === ~~row.id,
        ) as RegistrationClosureAttributes;
        const kindLabel = find(productTypeOptions, (x) => x.value === kind)?.label as string;
        const closureInitialValues = closure as RegistrationClosureAttributes;
        const parsed = starts_at && parse(starts_at, 'yyyy-MM-dd', new Date());
        const startsAtDate = starts_at && format(parsed as Date, 'dd-MM-yyyy');
        const onConfirm: onConfirm = async ({ setLoading, handleClose }) => {
          setLoading(true);
          await deleteRegistrationClosureMethod(closure?.id as string);
          setLoading(false);
          handleClose();
        };
        const registration_payment_simulation = registration.payment_simulation.selected_items.find((item) => {
          return item.registration_product_id === ~~row.id;
        }) as PaymentDataItems;
        return (
          <React.Fragment key={row.id}>
            <TableRow>
              <TableCell align='left'>{code}</TableCell>
              <TableCell align='center'>{payment_option_name}</TableCell>
              <TableCell align='center'>{kindLabel}</TableCell>
              <TableCell align='center'>{year}</TableCell>
              <TableCell align='center'>{product_name}</TableCell>
              <TableCell align='center'>{startsAtDate}</TableCell>
              <TableCell colSpan={3} align='center'>
                <ProductTableActions
                  generateBillings={generateBillings}
                  notification_email={row.notification_email}
                  verifyIfCanGenerateBillings={verifyIfCanGenerateBillings}
                  invoices_through_product={row.invoices_through_product}
                  payment_simulation={registration_payment_simulation}
                  verifyIfCanDeleteProductContracts={verifyIfCanDeleteProductContracts}
                  active_product={row.active}
                  closure={closure as RegistrationClosureAttributes}
                  closureInitialValues={closureInitialValues}
                  deleteRegistrationProduct={deleteRegistrationProduct}
                  disable_actions={open}
                  expandedClosureRow={expandedClosureRow}
                  expandedProductContractsRow={expandedProductContractsRow}
                  expandedRows={expandedRows}
                  expandRow={expandRow}
                  fetchRegistrationProducts={fetchRegistrationProducts}
                  name={product_name}
                  product_year={year}
                  profile={profile}
                  provision_months={row.provision_months}
                  registration_product_id={row.id}
                  setExpandedClosureRows={setExpandedClosureRows}
                  setExpandedProductContractsRow={setExpandedProductContractsRow}
                  setExpandedProductSubsidiesRow={setExpandedProductSubsidiesRow}
                  setExpandedRows={setExpandedRows}
                  starts_at={parsed && parsed.toISOString()}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={expandedClosureRow.includes(~~row.id)} timeout='auto' unmountOnExit>
                  <ClosureRow
                    active={row.active}
                    closure={closure as RegistrationClosureAttributes}
                    fetchRegistrationProducts={fetchRegistrationProducts}
                    onConfirm={onConfirm}
                    provision_months={row.provision_months}
                    product_year={year}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={expandedRows.includes(~~row.id)} timeout='auto' unmountOnExit>
                  <BillingTableContainer
                    fetchContracts={fetchContracts}
                    registration_product={row}
                    payment_simulation={registration.payment_simulation}
                    closure={closure}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={expandedProductSubsidiesRow.includes(~~row.id)} timeout='auto' unmountOnExit>
                  <RegistrationProductSubsidiesComponent
                    setExpandedProductSubsidiesRow={setExpandedProductSubsidiesRow}
                    selectProduct={selectProduct}
                    expandRow={expandRow}
                    registration_product_id={row.id}
                    fetchRegistrationProductPaymentData={fetchRegistrationProductPaymentData}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={expandedProductContractsRow.includes(~~row.id)} timeout='auto' unmountOnExit>
                  <RegistrationProductContracts fetchContracts={fetchContracts} registration_product={row} />
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const ProductStepTable = ({
  deleteRegistrationClosureMethod,
  deleteRegistrationProduct,
  expandedClosureRow,
  expandedProductContractsRow,
  expandedRows,
  fetchRegistrationProducts,
  registration,
  renderReady,
  selectProduct,
  setExpandedClosureRows,
  setExpandedProductContractsRow,
  setExpandedRows,
  tabValue,
  registrationProductsData,
  closuresData,
}: {
  registrationProductsData: RegistrationProductAttributes[];
  closuresData: RegistrationClosureAttributes[];
  deleteRegistrationClosureMethod: (id: string) => Promise<void>;
  deleteRegistrationProduct: ({ setLoading, handleClose }: any, registration_product_id: string) => Promise<void>;
  expandedClosureRow: number[];
  expandedProductContractsRow: number[];
  expandedRows: number[];
  fetchRegistrationProducts: () => Promise<void>;
  registration: CurrentRegistrationData;
  renderReady: boolean;
  selectProduct: (id: number) => void;
  setExpandedClosureRows: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedProductContractsRow: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedRows: React.Dispatch<React.SetStateAction<number[]>>;
  tabValue: number;
}) => {
  const classes = useStyles();

  if (!renderReady) {
    return <Loading />;
  }
  if (isEmpty(registrationProductsData)) {
    const message = tabValue === 0 ? 'ou ativos' : tabValue === 3 ? 'ou inativos' : '';
    return (
      <div>
        <span>{`Não há produtos inseridos ${message} no momento.`}</span>
      </div>
    );
  }
  return (
    <div className={classes.table}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((item, i) => (
                <TableCell key={item} align={![0, 1].includes(i) ? 'center' : 'left'}>
                  {item}
                </TableCell>
              ))}
              <TableCell align='center' colSpan={3}>
                {' '}
                Ações{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <ProductStepTableBody
            closuresData={closuresData}
            registrationProductsData={registrationProductsData}
            registration={registration}
            deleteRegistrationClosureMethod={deleteRegistrationClosureMethod}
            deleteRegistrationProduct={deleteRegistrationProduct}
            expandedClosureRow={expandedClosureRow}
            expandedProductContractsRow={expandedProductContractsRow}
            expandedRows={expandedRows}
            fetchRegistrationProducts={fetchRegistrationProducts}
            selectProduct={selectProduct}
            setExpandedClosureRows={setExpandedClosureRows}
            setExpandedProductContractsRow={setExpandedProductContractsRow}
            setExpandedRows={setExpandedRows}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductStepTable;
