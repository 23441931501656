import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  DELETE_REGISTRATION_PRODUCT,
  DeleteRegistrationProductPayload,
  UpdateRegistrationProductPayload,
  UPDATE_REGISTRATION_PRODUCT,
  FetchRegistrationProductPayload,
  FETCH_REGISTRATION_PRODUCT,
  CreateRegistrationProductPayload,
  CREATE_REGISTRATION_PRODUCT,
  GenerateRegistrationProductBillingPayload,
  GENERATE_REGISTRATION_PRODUCT_BILLINGS
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRegistrationProduct(action: DefaultAction<FetchRegistrationProductPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRegistrationProduct, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_REGISTRATION_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * generateBillings (action: DefaultAction<GenerateRegistrationProductBillingPayload, string>) :any {
  const { payload: {id}} = action
  try {
    const response = yield call(services.generateBillings, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(GENERATE_REGISTRATION_PRODUCT_BILLINGS.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(GENERATE_REGISTRATION_PRODUCT_BILLINGS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createRegistrationProduct (action: DefaultAction<CreateRegistrationProductPayload, string>): any {
  const { payload: { data} } = action
  try {
    const params = {
      data: {
        type: 'registration_products',
        attributes: { ...data },
      }
    }
    const response = yield call(services.createRegistrationProduct, {params})
    yield put(CREATE_REGISTRATION_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_REGISTRATION_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}




export function * updateRegistrationProduct (action: DefaultAction<UpdateRegistrationProductPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'registration_products',
        attributes: { ...data },
        id
      }
    }
    const response = yield call(services.updateRegistrationProduct, {params, id})
    yield put(UPDATE_REGISTRATION_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_REGISTRATION_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * deleteRegistrationProducts (action: DefaultAction<DeleteRegistrationProductPayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteRegistrationProduct, {
      id
    })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_REGISTRATION_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_REGISTRATION_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRegistrationProductsSagas () {
  yield takeLatest(DELETE_REGISTRATION_PRODUCT.request, deleteRegistrationProducts)
  yield takeLatest(UPDATE_REGISTRATION_PRODUCT.request, updateRegistrationProduct)
  yield takeLatest(FETCH_REGISTRATION_PRODUCT.request, fetchRegistrationProduct)
  yield takeLatest(CREATE_REGISTRATION_PRODUCT.request, createRegistrationProduct)
  yield takeLatest(GENERATE_REGISTRATION_PRODUCT_BILLINGS.request, generateBillings)
}

export default function * registrationProductsSagas () {
  yield all([watchRegistrationProductsSagas()])
}
