/* eslint-disable camelcase */
import { DoDisturbAlt } from '@mui/icons-material'
import { filter, isEmpty, isNil, map, some } from 'lodash'
import React from 'react'
import { error, success, warning } from 'react-notification-system-redux'
import { useDispatch, useSelector } from 'react-redux'
import { formValueSelector, isValid } from 'redux-form'
import { RootState } from '../../store/configureStore'
import { CreateRegistrationClosurePayloadData, CREATE_REGISTRATION_CLOSURE, UPDATE_REGISTRATION_CLOSURE } from '../../store/registration_closures'
import { colors, ContractAttributes, BillingAttributes, DefaultOptionType, InvoiceAttributes, InvoiceChargeStatusEnum, InvoiceItemAttributes, InvoiceStatusEnum, onConfirm, RegistrationClosureAttributes } from '../../utils/constants'
import RegistrationClosureForm from '../form/RegistrationClosureForm'
import { IconModal } from '../modal/Modal'
import { FETCH_CONTRACTS } from '../../store/contracts'
import { InvoiceJson } from '../../store/invoices'
import { BillingJson } from '../../store/billings'
import { InvoiceItemJson } from '../../store/invoice_items'
import { FETCH_REGISTRATION_CLOSURE_STATUSES } from '../../store/registration_closure_statuses'
import { FETCH_REGISTRATION_CLOSURE_REASONS } from '../../store/registration_closure_reasons'

const CloseRegistrationProduct= ({
  registration_product_id,
  initialValues,
  fetchRegistrationProducts,
  product_year,
  provision_months,
  active
} : {
  registration_product_id: string,
  initialValues?: RegistrationClosureAttributes
  fetchRegistrationProducts: () => Promise<void>
  product_year: number
  provision_months: number[]
  active: boolean
}) => {
  const [invoices, setInvoicesData] = React.useState<InvoiceAttributes[]>([])
  const [invoice_items, setInvoiceItemsData] = React.useState<InvoiceItemAttributes[]>([])
  const [contracts, setContractsData] = React.useState<ContractAttributes[]>([])
  const [billings, setBillingsData] = React.useState<BillingAttributes[]>([])
  const [closureStatusOptions, setClosureStatusOptions] = React.useState<DefaultOptionType[]>([])
  const [closureReasonOptions, setClosureReasonOptions] = React.useState<DefaultOptionType[]>([])
  const formName = 'registrationClosureForm'
  const dispatch = useDispatch()
  const formValues = formValueSelector(formName);
  const state = useSelector((state: RootState) => state)
  const isFormValid = isValid(formName)(state)
  const registration_closure_status_id = formValues(state, 'registration_closure_status_id') as number
  const registration_closure_reason_id = formValues(state, 'registration_closure_reason_id') as number
  const closure_date = formValues(state, 'closure_date') as Date
  const details = formValues(state, 'details') as string
  const penalty = formValues(state, 'penalty')
  const refund_registration_fee = formValues(state, 'refund_registration_fee')

  const fetchClosureStatusOptions = React.useCallback(async () => {
    try {
      const statuses = await dispatch(
        FETCH_REGISTRATION_CLOSURE_STATUSES.request({
          params: {
            filters: {
              'page[size]': '100',
            }
          }  
        })
      )
      const {
        data: { data }
      } = statuses
      const formattedStatuses = data.map(status => {
        return ({
          value: status.id,
          label: status.attributes.name
        })
      })
      setClosureStatusOptions(formattedStatuses)
    } catch (error) {
      dispatch(
        warning({
          message: 'Erro no carregamento dos status de encerramento de matrícula'
        })
      )
    }
  }, [])

  const fetchClosureReasonOptions = React.useCallback(async () => {
    try {
      const reasons = await dispatch(
        FETCH_REGISTRATION_CLOSURE_REASONS.request({
          params: {
            filters: {
              'page[size]': '100',
            }
          }  
        })
      )
      const {
        data: { data }
      } = reasons
      const formattedReasons = data.map(reason => {
        return ({
          value: reason.id,
          label: reason.attributes.name
        })
      })

      setClosureReasonOptions(formattedReasons)
    } catch (error) {
      dispatch(
        warning({
          message: 'Erro no carregamento das razões de encerramento de matrícula'
        })
      )
    }
  }, [])


  const fetchRegistrationProductContracts = React.useCallback(async () => {
      const response = await dispatch(
        FETCH_CONTRACTS.request({
          params: {
            filters: {
              'q[registration_product_id_eq]': registration_product_id,
              'include': [
                'billings.invoice_items.invoice'
              ].join(',')
            }
          }
        })
      )
      const { data: { data, included } } = response
      const contracts = data.map(item => ({id: item.id, ...item.attributes})) as ContractAttributes[]
      const includedInvoices = map(filter(included, incl => incl.type === 'invoices') as InvoiceJson[], item => ({
        id: item.id,
        ...item.attributes
      }))

      const includedBillings = map(filter(included, incl => incl.type === 'billings' && incl.attributes.billable_type === 'Contract') as BillingJson[], item => ({
        id: item.id,
        ...item.attributes
      }))
      const includedInvoiteItems = map(filter(included, incl => incl.type === 'invoice_items') as InvoiceItemJson[], item => ({
        id: item.id,
        ...item.attributes
      }))
      setContractsData(contracts)
      setBillingsData(includedBillings)
      setInvoicesData(includedInvoices)
      setInvoiceItemsData(includedInvoiteItems)
  }, [registration_product_id])
  const initData = async () => {
    await fetchRegistrationProductContracts()
    await fetchClosureReasonOptions()
    await fetchClosureStatusOptions()
  }
  const createRegistrationClosureMethod = React.useCallback(async (data: CreateRegistrationClosurePayloadData) => {
    try {
      await dispatch(
        CREATE_REGISTRATION_CLOSURE.request({data})
      )
      dispatch(
        success(
          {
            message: 'Produto cancelado com sucesso'
          }
        )
      )
      await fetchRegistrationProducts()
    } catch (err) {
      dispatch(error({
        message: 'Erro ao cancelar produto'
      }))
    }
  }, [
    fetchRegistrationProducts,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    registration_product_id,
    penalty,
    refund_registration_fee
  ])

  const updateRegistrationClosureMethod = React.useCallback(async (data: CreateRegistrationClosurePayloadData) => {
    try {
      if(initialValues?.id){
        await dispatch(
          UPDATE_REGISTRATION_CLOSURE.request({data, id: initialValues.id})
        )
        dispatch(
          success(
            {
              message: 'Cancelamento do produto atualizado com sucesso'
            }
          )
        )
        await fetchRegistrationProducts()
      }
    } catch (err) {
      dispatch(error({
        message: 'Erro ao atualizar cancelamento do produto'
      }))
    }
  },[
    initialValues,
    fetchRegistrationProducts,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    registration_product_id
  ])


  const onConfirm: onConfirm = React.useCallback(async ({ setLoading, handleClose }) => {
    
    const data = {
      registration_product_id: registration_product_id,
      registration_closure_status_id,
      registration_closure_reason_id,
      closure_date: closure_date instanceof Date ? closure_date.toISOString() : closure_date,
      details: details,
      penalty,
      refund_registration_fee
    }
    setLoading(true)
    if(initialValues?.id){
      await updateRegistrationClosureMethod(data)
    } else {
      await createRegistrationClosureMethod(data)
    }
    setLoading(false)
    handleClose()
  }, [
    initialValues,
    updateRegistrationClosureMethod,
    createRegistrationClosureMethod,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    registration_product_id,
    penalty,
    refund_registration_fee
  ])
  const disabled = some([registration_closure_reason_id, registration_closure_status_id, closure_date, details], item => isNil(item) || !item) || !isFormValid

  const isEditing = initialValues?.id
  const tooltipText = isEditing ? 'Alterar dados de cancelamento' : 'Cancelar produto'
  const remaining_invoices = contracts.reduce((acc, contract) => {
    if(isEmpty(invoices) || !closure_date){
      return acc
    }
    const cbs = billings.filter((cb) => {
      return cb.billable_id === ~~contract.id && cb.billable_type === 'Contract'
    })
    const provision_months = cbs.map(cb => cb.service_month)
    const months_to_be_canceled = provision_months.filter(pm => pm > 0 && new Date(product_year, pm - 1) > closure_date)
    const provision_months_without_reg_fee = provision_months.filter(pm => pm > 0)
    if(provision_months.includes(0) && refund_registration_fee && new Date(closure_date) < new Date(product_year, Math.min(...provision_months_without_reg_fee) - 1)) {
      months_to_be_canceled.unshift(0)
    }
    if(isEmpty(months_to_be_canceled)) {
      return acc
    }
    const cbs_to_be_canceled = cbs.filter(cb => months_to_be_canceled.includes(cb.service_month))
    const cb_ids = cbs_to_be_canceled.map(cb => cb.id)
    const cb_invoices = invoices.filter(invoice => {
      const ii = invoice_items.filter(invoice_item => invoice_item.invoice_id === ~~invoice.id && cb_ids.includes(invoice_item.billing_id.toString()))
      return invoice.status === InvoiceStatusEnum.FINISHED && [InvoiceChargeStatusEnum.RECEIVED, InvoiceChargeStatusEnum.CONFIRMED].includes(invoice.charge_status as InvoiceChargeStatusEnum) && !isEmpty(ii)
    })
    return acc.concat(cb_invoices)
  }, [] as InvoiceAttributes[])
  const is_closed = initialValues?.id && initialValues.closure_date && new Date(initialValues.closure_date) <= new Date() && !active
  const date_for_comparison = new Date(product_year, Math.min(...provision_months.filter(pm => pm > 0)) - 1, 1)

  const disable_display_refund_registration_fee = !provision_months.includes(0) || !closure_date || !(closure_date && new Date(closure_date) < date_for_comparison)
  const closureDatePickerProps = {
    ...(is_closed ? {
      maxDate: new Date(initialValues.closure_date)
    } : {})
  }
  return(
        <IconModal
          icon={DoDisturbAlt}
          iconProps={{style:{color: colors.darkBlue}}}
          disableConfirm={disabled}
          onConfirm={onConfirm}
          title='Cancelamento de produto'
          tooltipText={tooltipText}
          confirmButtonText='Salvar'
          onOpen={initData}
        >
          <RegistrationClosureForm
            disable_display_refund_registration_fee={disable_display_refund_registration_fee}
            closureReasonOptions={filter(closureReasonOptions, option => option.label !== 'Contrato Finalizado')}
            closureStatusOptions={filter(closureStatusOptions, option => option.label !== 'Concluído')}
            initialValues={initialValues}
            closure_date={closure_date}
            closureDatePickerProps={closureDatePickerProps}
          />
          {
            !isEmpty(remaining_invoices) && (
              <div style={{ display: 'grid', width:'100%', gap: '10%' }}>
                <span className='subtitle-two'>Faturas a serem estornadas</span>
                {remaining_invoices.map(invoice => <span key={invoice.code}>{invoice.code}</span>)}
              </div>
            )
          }
        </IconModal>
  )
}

export default CloseRegistrationProduct