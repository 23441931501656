/* eslint-disable camelcase */
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { SubjectAttributes, DefaultOptionType, Paths, Role, validation, subjectFieldOptions, subjectKindOptions } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 10%',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const SubjectsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: SubjectAttributes;
}) => {
  const { handleSubmit, onSubmit, initialValues } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector('subjectForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);

  const companies_options = getCompanyFilterOptions({
    is_above_school_admin,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_admin && !initialValues?.id;

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar disciplina</span>
      <form className={classes.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da disciplina'}
                validate={[validation.required]}
              />
              <Field
                name='field'
                component={SelectComponent}
                options={subjectFieldOptions}
                label={'Àrea'}
                placeholder={'Selecione a área'}
              />
              <Field
                name='kind'
                component={SelectComponent}
                options={subjectKindOptions}
                label={'Tipo'}
                placeholder={'Selecione o tipo'}
              />
            </div>
            <div className={classes.buttons}>
              <Link to={Paths.SUBJECTS_LIST} className='red small'>
                <span> Cancelar </span>
              </Link>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar disciplina </span>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'subjectForm',
  }),
)(SubjectsForm);
