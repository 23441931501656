/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { CourseJson } from '../courses'
import { AxiosResponse } from 'axios'
import { AddressJson, UserJson } from '../users'
import { AccountJson } from '../accounts'
import { CompanyJson } from '../companies'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { UserAlergyJson } from '../alergies'
import { UserDeficiencyJson } from '../deficiencies'

export interface TeacherAttrs {
  account_id: number
  teacher_name: string
  teacher_company_name: string
  teacher_company_id: number
}
export type TeacherJson = JsonFormat<'teachers', TeacherAttrs>

export type FetchTeachersResponse = JsonResponseFormat<TeacherJson[], (CourseJson)[]>
export type FetchTeacherResponse = JsonResponseFormat<TeacherJson, (CourseJson|UserJson|AccountJson|CompanyJson|AddressJson|CityJson|StateJson|
  UserAlergyJson|UserDeficiencyJson)[]>
export type FetchTeachersPayload = WithRequestParams
export interface FetchTeacherPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateTeacherPayload {
  data: Partial<TeacherAttrs>
}

export interface UpdateTeacherPayload extends CreateTeacherPayload {
  id: string
}

export interface DeleteTeacherPayload {
  id: string
}

export const FETCH_TEACHERS= createPromiseAction('teachers: FETCH_TEACHERS')<FetchTeachersPayload, FetchTeachersResponse, any>()
export const FETCH_TEACHER = createPromiseAction('teachers: FETCH_TEACHER')<FetchTeacherPayload, FetchTeacherResponse, any>()
export const CREATE_TEACHER= createPromiseAction('teachers: CREATE_TEACHER')<CreateTeacherPayload, FetchTeacherResponse, any>()
export const UPDATE_TEACHER= createPromiseAction('teachers: UPDATE_TEACHER')<UpdateTeacherPayload, FetchTeacherResponse, any>()
export const DELETE_TEACHER = createPromiseAction('teachers: DELETE_TEACHER')<DeleteTeacherPayload, AxiosResponse, any>()
