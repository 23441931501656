import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, KtwelveSubjectEvaluationKindEnum, KtwelveSubjectKindEnum, WithRequestParams } from "../../utils/constants"
import { AxiosResponse } from "axios"
import { SubjectPeriodJson } from "../subject_periods"

export interface KtwelveSubjectAttrs {
  ktwelve_curriculum_id: number
  curriculum_subject_id: number
  ktwelve_name: string
  subject_name: string
  kind: KtwelveSubjectKindEnum
  evaluation_kind: KtwelveSubjectEvaluationKindEnum
  parent_name: string
  parent_id: number
}

export type KtwelveSubjectJson = JsonFormat<'ktwelve_subjects', KtwelveSubjectAttrs>
export type FetchKtwelveSubjectsResponse = JsonResponseFormat<KtwelveSubjectJson, (KtwelveSubjectJson|SubjectPeriodJson)[]>
export type FetchKtwelveSubjectResponse = JsonResponseFormat<KtwelveSubjectJson, (KtwelveSubjectJson|SubjectPeriodJson)[]>
export type FetchKtwelveSubjectsPayload = WithRequestParams

export interface FetchKtwelveSubjectPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateKtwelveSubjectPayload {
  data: object
}

export interface UpdateKtwelveSubjectPayload extends CreateKtwelveSubjectPayload {
  id: string
}

export interface DeleteKtwelveSubjectPayload {
  id: string
}


export const FETCH_KTWELVE_SUBJECTS = createPromiseAction('formulas: FETCH_KTWELVE_SUBJECTS')<FetchKtwelveSubjectsPayload, FetchKtwelveSubjectsResponse, any>()
export const FETCH_KTWELVE_SUBJECT  = createPromiseAction('formulas: FETCH_KTWELVE_SUBJECT')<FetchKtwelveSubjectPayload, FetchKtwelveSubjectResponse, any>()
export const CREATE_KTWELVE_SUBJECT = createPromiseAction('formulas: CREATE_KTWELVE_SUBJECT')<CreateKtwelveSubjectPayload, FetchKtwelveSubjectResponse, any>()
export const UPDATE_KTWELVE_SUBJECT = createPromiseAction('formulas: UPDATE_KTWELVE_SUBJECT')<UpdateKtwelveSubjectPayload, FetchKtwelveSubjectResponse, any>()
export const DELETE_KTWELVE_SUBJECT = createPromiseAction('formulas: DELETE_KTWELVE_SUBJECT')<DeleteKtwelveSubjectPayload, AxiosResponse, any>()
