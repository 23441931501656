import DashboardTemplate from '../components/template/DashboardTemplate'
import NotLoggedTemplate from '../components/template/NotLoggedTemplate'
import PowerBiTemplate from '../components/template/PowerBiTemplate'
import Home from '../pages/HomePage'
import NonLoggedSomaPage from '../pages/NonLoggedSomaPage'
import { Company, Paths, ProfileTypes, private_routes_permissions } from '../utils/constants'
import CompanyFormPage from '../pages/CompanyFormPage'
import UsersPage from '../pages/UsersPage'
import CreateAccountPage from '../pages/AccountFormPage'
import SomaLoginPage from '../pages/SomaLoginPage'
import SecretaryPage from '../pages/SecretaryPage'
import RegistrationsPage from '../pages/RegistrationsPage'
import RegistrationDetailsPage from '../pages/RegistrationDetailsPage'
import DashboardPage from '../pages/DashboardPage'
import ContractsPage from '../pages/ContractsPage'
import React from 'react'
import { RouteProps } from 'react-router-dom'
import ProductsPage from '../pages/ProductsPage'
import ProductFormPage from '../pages/ProductFormPage'
import ContractSettingsPage from '../pages/ContractSettingsPage'
import ContractSettingsFormPage from '../pages/ContractSettingsFormPage'
import DiscountTypesPage from '../pages/DiscountTypesPage'
import DiscountTypesFormPage from '../pages/DiscountTypesFormPage'
import CoursesPage from '../pages/CoursesPage'
import CoursesFormPage from '../pages/CoursesFormPage'
import KtwelvesPage from '../pages/KtwelvesPage'
import KtwelvesFormPage from '../pages/KtwelvesFormPage'
import ClassroomsPage from '../pages/ClassroomsPage'
import ClassroomsFormPage from '../pages/ClassroomsFormPage'
import ProductFamiliesPage from '../pages/ProductFamiliesPage'
import ProductFamiliesFormPage from '../pages/ProductFamiliesFormPage'
import ProfileDashboardsPage from '../pages/ProfileDashboardsPage'
import ProfileDashboardsFormPage from '../pages/ProfileDashboardsPageForm'
import RepresentativeInvoicesPage from '../pages/RepresentativeInvoicesPage'
import AsaasWebhookSettingsFormPage from '../pages/AsaasWebhookSettingsFormPage '
import InvoicesPage from '../pages/InvoicesPage'
import WalletsPage from '../pages/WalletsPage'
import CoordinatorsPage from '../pages/CoordinatorsPage'
import CoordinatorsFormPage from '../pages/CoordinatorsFormPage'
import TeachersPage from '../pages/TeachersPage'
import TeachersFormPage from '../pages/TeachersFormPage'
import SubjectsPage from '../pages/SubjectsPage'
import SubjectFormPage from '../pages/SubjectFormPage'
import CurriculumsPage from '../pages/CurriculumsPage'
import CurriculumsFormPage from '../pages/CurriculumsFormPage'
/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */

export interface Route extends Omit<RouteProps, "component"> {
  path: string,
  component: React.ElementType,
  template?: React.ElementType,
  default?:boolean,
  permission?: ProfileTypes[],
  companies?: Company[]
  onlyNoAuth?: boolean,
  key?: string,
  exact?: boolean
}


export const privateRoutes: Route[] = [
  {
    path: Paths.HOME,
    component: Home,
    template: DashboardTemplate,
    default: true,
  },
  {
    path: Paths.COMPANY_FORM,
    component: CompanyFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.USERS_LIST,
    component: UsersPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.USERS_FORM,
    component: CreateAccountPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.SECRETARY,
    component: RegistrationsPage,
    template: DashboardTemplate,
    default: true,
  },
  {
    path: Paths.REGISTRATIONS,
    component: RegistrationsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.REGISTRATIONS_FORM,
    component: SecretaryPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.REGISTRATION_DETAILS,
    component: RegistrationDetailsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.CONTRACTS_LIST,
    component: ContractsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.DASHBOARD,
    component: DashboardPage,
    template: PowerBiTemplate,
    default: true
  },
  {
    path: Paths.PROFILE_DASHBOARDS_LIST,
    component: ProfileDashboardsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PROFILE_DASHBOARDS_FORM,
    component: ProfileDashboardsFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PRODUCTS_LIST,
    component: ProductsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PRODUCTS_LIST,
    component: ProductsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PRODUCTS_FORM,
    component: ProductFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.CONTRACT_SETTINGS_LIST,
    component: ContractSettingsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.WALLETS_LIST,
    component: WalletsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.ASAAS_PAYMENT_SETTINGS_FORM,
    component: AsaasWebhookSettingsFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.CONTRACT_SETTINGS_FORM,
    component: ContractSettingsFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.DISCOUNT_TYPES_LIST,
    component: DiscountTypesPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.DISCOUNT_TYPES_FORM,
    component: DiscountTypesFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.CLASSROOM_LIST,
    component: ClassroomsPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.CLASSROOM_FORM,
    component: ClassroomsFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.COURSES_LIST,
    component: CoursesPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.COURSES_FORM,
    component: CoursesFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.KTWELVES_LIST,
    component: KtwelvesPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.KTWELVES_FORM,
    component: KtwelvesFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PRODUCT_FAMILY_LIST,
    component: ProductFamiliesPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.PRODUCT_FAMILY_FORM,
    component: ProductFamiliesFormPage,
    template: DashboardTemplate,
  },
  {
    path: Paths.REPRESENTATIVE_INVOICES,
    component: RepresentativeInvoicesPage,
    default: true,
    template: DashboardTemplate,
  },
  {
    path: Paths.INVOICES,
    component: InvoicesPage,
    default: true,
    template: DashboardTemplate,
  },
  {
    path: Paths.COORDINATORS_FORM,
    component: CoordinatorsFormPage,
    template: DashboardTemplate
  },
  {
    path: Paths.COORDINATORS_LIST,
    component: CoordinatorsPage,
    template: DashboardTemplate
  },
  {
    path: Paths.TEACHERS_LIST,
    component: TeachersPage,
    default: true,
    template: DashboardTemplate
  },
  {
    path: Paths.TEACHERS_FORM,
    component: TeachersFormPage,
    template: DashboardTemplate
  },
  {
    path: Paths.SUBJECTS_LIST,
    component: SubjectsPage,
    template: DashboardTemplate
  },
  {
    path: Paths.SUBJECTS_FORM,
    component: SubjectFormPage,
    template: DashboardTemplate
  },
  {
    path: Paths.CURRICULUMS_LIST,
    component: CurriculumsPage,
    template: DashboardTemplate
  },
  {
    path: Paths.CURRICULUMS_FORM,
    component: CurriculumsFormPage,
    template: DashboardTemplate
  },
  {
    path: Paths.CURRICULUMS_COMPOSITIONS_LIST,
    component: CurriculumsFormPage,
    template: DashboardTemplate
  },
  {
    path: Paths.CURRICULUM_CURRICULUM_KTWELVES,
    component: CurriculumsFormPage,
    template: DashboardTemplate
  }
].map(item => {
  const private_route = private_routes_permissions.find(route => item.path === route.path)
  return ({
    ...item,
    permission: private_route?.permission || [],
    companies: private_route?.companies || []
  })
})

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes: Route[] = [
  {
    path: Paths.HOME,
    component: NonLoggedSomaPage,
    template: NotLoggedTemplate,
    onlyNoAuth: true
  },
  {
    path: Paths.LOGIN,
    component: SomaLoginPage,
    onlyNoAuth: true
  }
]

/**
 * Both logged and not logged users can access these routes
 *
 * - Minor precedence when user IS LOGGED
 *    Is overwritten by the private route with same paths
 * - Major precedence when user IS NOT LOGGED
 *    Overwrites the same paths of the private and not logged routes
 */
export const publicRoutes: Route[] = [
  // {
  //   path: '/',
  //   component: Home
  // }
]
