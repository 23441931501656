/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  colors,
  DefaultOptionType,
  validation,
  KtwelveCurriculumAttributes,
} from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { compact, reduce } from 'lodash';
import { FETCH_KTWELVES } from '../../store/ktwelves';
import SelectComponent from '../input/form/select';
import { FETCH_CURRICULUM } from '../../store/curriculums';
import Loading from '../loading/Loading';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
    accordion: any;
    accordionSummary: any;
  } => ({
    accordion: {
      background: 'inherit',
      'box-shadow': 'none',
      'border-bottom': `1px solid ${colors.grayBlue}`,
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'inherit',
      },
    },
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'ktwelveCurriculumsForm';

const KtwelveCurriculumsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: KtwelveCurriculumAttributes;
  close_form: () => void
  all_ktwelve_curriculums: KtwelveCurriculumAttributes[]
  curriculum_id: string
  course_id: string
}) => {
  const { handleSubmit, onSubmit, initialValues, close_form, all_ktwelve_curriculums, curriculum_id, course_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingKtwelves, setLoadingKtwelves] = React.useState(false)
  const [ktwelveOptions, setKtwelveOptions] = React.useState<DefaultOptionType[]>([]);
  const [compositionOptions, setCompositionOptions] = React.useState<DefaultOptionType[]>([])

  const fetchKtwelveDegreesMethod = React.useCallback(async (value: string) => {
    try {
      setLoadingKtwelves(true);
      const used_ktwelve_ids = compact(
        all_ktwelve_curriculums.map((item) => item.ktwelve_degree_id),
      );
      const ktwelves = await dispatch(
        FETCH_KTWELVES.request({
          params: {
            filters: {
              'q[name_cont]': value,
              'q[id_not_in]': used_ktwelve_ids,
              'q[course_id_eq]': course_id
            },
          },
        }),
      );
      if (ktwelves) {
        const {
          data: { data },
        } = ktwelves;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setKtwelveOptions(formattedData);
        setLoadingKtwelves(false);
      }
    } catch (e) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de série',
        }),
      );
    }
  }, []);

  const fetchCompositions = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_CURRICULUM.request({
          id: curriculum_id,
          params: {
            filters: {
              include: 'compositions',
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const compositions_attributes = reduce(
        included,
        (acc, incl) => {
          if (incl.type === 'compositions') {
            return acc.concat({ value: incl.id, label: incl.attributes.name });
          }
          return acc;
        },
        [] as DefaultOptionType[],
      );
      setCompositionOptions(compositions_attributes);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao carregar e matriz curricular',
          autoDismiss: 3,
        }),
      );
    }
  }, [curriculum_id]);

  const init = async () => {
    setLoading(true);
    await fetchCompositions();
    if (initialValues.id) {
      setKtwelveOptions([
        {
          value: initialValues.ktwelve_degree_id.toString(),
          label: initialValues.ktwelve_degree_name,
        },
      ]);
    }
    setLoading(false);
  };


  React.useEffect(() => {
    init()
  }, []);

  if(loading){
    return <Loading />
  }
  return (
    <div className={classes.view}>
      <form className={classes.form}>
        <>
          <div style={{ width: '100%' }}>
            <Field
              name='ktwelve_degree_id'
              label={'Série'}
              placeholder={'Série'}
              component={AutoCompleteComponent}
              autoCompleteProps={{
                filterOptions: (x: any) => x,
                loading: loadingKtwelves,
                getValueOnChange: true,
                fetchOptions: fetchKtwelveDegreesMethod,
              }}
              options={ktwelveOptions}
              validate={[validation.required]}
            />
            <Field
              label={'Composição'}
              placeholder={'Selecionar composição'}
              name='composition_id'
              component={SelectComponent}
              options={compositionOptions}
              validate={[validation.required]}
            />
          </div>
          <div className={classes.buttons}>
            <button
              onClick={(e) => {
              e.preventDefault()
              close_form()
              }} 
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar Série </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(KtwelveCurriculumsForm);
