import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { error } from 'react-notification-system-redux';
import { format } from 'date-fns';

import {
  FETCH_CONTRACTS,
  FetchContractsResponse,
} from '../store/contracts';

import ContractsView from '../components/view/Secretary/ContractsView';
import { RootState } from '../store/configureStore';
import {
  PaginationType,
  ContractsViewData,
  ContractStatusTypes,
  PeriodTypes,
  ProductTypes,
  PendingSignaturesStatusTypes,
  PendingSignatureStatusEnum,
  OrderOptions,
  ContractAttributes,
} from '../utils/constants';
import { reduce } from 'lodash';
import { get_company_search } from '../utils/functions';

const defaultPagination = {
  pageCount: 0,
  totalCount: 0,
  pageSize: 10,
  pageNumber: 0,
};

const searchForOptions = [
  {
    value: 'q[registration_account_user_name_cont]',
    label: 'Aluno',
  },
  {
    value: 'q[representative_account_user_name_cont]',
    label: 'Responsável',
  },
];
const initial_data = {
  accounts: [],
  classrooms:[],
  companies: [],
  billings:[],
  contracts: [],
  invoice_items: [],
  invoices: [],
  ktwelves: [],
  payment_options: [],
  payment_collections: [],
  products: [],
  registrations: [],
  registration_products: [],
  representative_products: [],
  representatives: [],
  users: []
}

const transformResponseData = (response: FetchContractsResponse) => {
  const {
    data: { data, included },
  } = response;
  const contracts = data.reduce((acc, invoice) => {
    return acc.concat({
    id: invoice.id,
    ...invoice.attributes,
    })
  }, [] as ContractAttributes[]);
  const formatted_data = reduce(
    included,
    (acc, incl) => {
      let new_acc = acc;
      const acc_keys = Object.keys(acc);
      acc_keys.forEach((key) => {
        if (key === incl.type) {
          new_acc = {
            ...new_acc,
            [key]: [...new_acc[key as keyof ContractsViewData], { id: incl.id, ...incl.attributes }],
          };
        }
      });
      return new_acc;
    },
    {...initial_data, contracts} as ContractsViewData,
  );
  return formatted_data;
};


const ContractContainer = () => {
  const [contractsData, setContractsData] = React.useState<ContractsViewData>(initial_data);
  const [renderReady, setRenderReady] = React.useState(false);
  const [filterOption, setFilterOption] = React.useState<string>('q[registration_account_user_name_cont]');
  const [ktwelveFilterValue, setKtwelveFilterValue] = React.useState('');
  const [classroomFilterValue, setClassroomFilterValue] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [contractNumberFilterValue, setContractNumberFilterValue] = React.useState('');
  const [statusFilterValue, setStatusFilterValue] = React.useState<ContractStatusTypes | string>('');
  const [pendingSignatureStatusFilterValue, setPendingSignatureStatusFilterValue] = React.useState<
    PendingSignaturesStatusTypes | string
  >(PendingSignatureStatusEnum.PENDING);
  const [periodFilterValue, setPeriodFilterValue] = React.useState<PeriodTypes | string>('');
  const [yearFilterValue, setYearFilterValue] = React.useState<Date | null>(new Date(2024, 0));
  const [productTypeFilterValue, setProductTypeFilterValue] = React.useState<ProductTypes | string>('');
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const [tab, setTab] = React.useState<'contracts' | 'signatures'>('contracts');
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState<OrderOptions>('asc');

  const dispatch = useDispatch();
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = useSelector((state: RootState) => state);

  const fetchContractsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      status = statusFilterValue as ContractStatusTypes,
      period = periodFilterValue as PeriodTypes,
      year = yearFilterValue,
      productType = productTypeFilterValue,
      company_filter = companyFilterValue,
      order_filter = orderBy,
      order_direction_filter = order,
    }: {
      newPagination?: PaginationType;
      status?: ContractStatusTypes | string;
      period?: PeriodTypes | string;
      year?: Date | null;
      productType?: ProductTypes | string;
      tabValue?: 'contracts' | 'signatures';
      pendingSignatureStatus?: PendingSignaturesStatusTypes | string;
      company_filter?: string;
      order_filter?: string;
      order_direction_filter?: string;
    }) => {
      let yearFilter = {};
      let orderFilter = {};
      if (year) {
        yearFilter = {
          'q[product_periods_starts_at_gteq]': format(new Date(year.getFullYear(), 0, 1, 1), 'yyyy-MM-dd'),
          'q[product_periods_starts_at_lteq]': format(new Date(year.getFullYear(), 11, 31), 'yyyy-MM-dd'),
        };
      }
      if (order_filter && order_direction_filter) {
        orderFilter = {
          'q[s]': `${order_filter} ${order_direction_filter}`,
        };
      }
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      const response = await dispatch(
        FETCH_CONTRACTS.request({
          params: {
            filters: {
              'q[registration_account_company_id_in]': companies_array,
              include:
                [
                 'representative.account.user',
                 'product.classroom.ktwelve_degree',
                 'registration.account.company',
                ].join(','),
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': newPagination.pageSize.toString(),
              'q[status_eq]': status,
              'q[product_classroom_ktwelve_degree_name_cont]': ktwelveFilterValue,
              'q[contract_number_cont]': contractNumberFilterValue,
              'q[product_classroom_name_cont]': classroomFilterValue,
              'q[product_classroom_period_eq]': period,
              'q[product_kind_eq]': productType || '',
              [filterOption]: filterValue,
              ...yearFilter,
              ...orderFilter,
            },
          },
        }),
      );
      
      const result = transformResponseData(response)
      return { data: result, meta:response.data.meta };
    },
    [
      profile,
      company,
      pagination,
      statusFilterValue,
      periodFilterValue,
      yearFilterValue,
      productTypeFilterValue,
      pendingSignatureStatusFilterValue,
      contractNumberFilterValue,
      companyFilterValue,
      order,
      orderBy,
    ],
  );

  const fetchContractItemsMethod = async ({
    ...rest
  }: {
    tabValue?: 'contracts' | 'signatures';
    newPagination?: PaginationType;
    status?: ContractStatusTypes | string;
    period?: PeriodTypes | string;
    year?: Date | null;
    productType?: ProductTypes | string;
  }) => {
    try {
      setRenderReady(false);
      const result = await fetchContractsMethod({...rest})
      setPagination((current) => ({
        ...current,
        pageCount: result.meta.page_count,
        totalCount: result.meta.total_count,
      }));
      setContractsData(result.data);
      setRenderReady(true);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro no carregamento dos contratos',
        }),
      );
    }
  };

  const handleTabChange = (_: any, newValue: 'contracts' | 'signatures') => {
    setContractsData(initial_data);
    setTab(newValue);
    fetchContractItemsMethod({ tabValue: newValue });
  };

  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    fetchContractItemsMethod({
      newPagination: {
        ...pagination,
        pageNumber: newPage,
      },
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    fetchContractItemsMethod({
      newPagination: {
        ...pagination,
        pageSize: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    fetchContractItemsMethod({ newPagination: defaultPagination });
  }, []);

  return (
    <ContractsView
      classroomFilterValue={classroomFilterValue}
      companyFilterValue={companyFilterValue}
      contractNumberFilterValue={contractNumberFilterValue}
      data={contractsData}
      defaultPagination={defaultPagination}
      fetchContractsMethod={fetchContractItemsMethod}
      filterOption={filterOption}
      filterValue={filterValue}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      handleTabChange={handleTabChange}
      ktwelveFilterValue={ktwelveFilterValue}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      pagination={pagination}
      pendingSignatureStatusFilterValue={pendingSignatureStatusFilterValue}
      periodFilterValue={periodFilterValue}
      productTypeFilterValue={productTypeFilterValue}
      profile={profile}
      renderReady={renderReady}
      searchForOptions={searchForOptions}
      setClassroomFilterValue={setClassroomFilterValue}
      setCompanyFilterValue={setCompanyFilterValue}
      setContractNumberFilterValue={setContractNumberFilterValue}
      setFilterOption={setFilterOption}
      setFilterValue={setFilterValue}
      setKtwelveFilterValue={setKtwelveFilterValue}
      setPendingSignatureStatusFilterValue={setPendingSignatureStatusFilterValue}
      setPeriodFilterValue={setPeriodFilterValue}
      setProductTypeFilterValue={setProductTypeFilterValue}
      setStatusFilterValue={setStatusFilterValue}
      setYearFilterValue={setYearFilterValue}
      statusFilterValue={statusFilterValue}
      tab={tab}
      yearFilterValue={yearFilterValue}
    />
  );
};

export default ContractContainer;
