/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface ProductFamilyAttrs {
  name: string
  description: string
  invoices_through_product: boolean
}

export type ProductFamilies = JsonFormat<'product_families', ProductFamilyAttrs>

export type FetchProductFamiliesResponse = JsonResponseFormat<ProductFamilies[]>
export type FetchProductFamilyResponse = JsonResponseFormat<ProductFamilies>

export type FetchProductFamiliesPayload = WithRequestParams

export interface FetchProductFamilyPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateProductFamilyPayload {
  data: object
}

export interface UpdateProductFamilyPayload extends CreateProductFamilyPayload {
  id: string
}

export const FETCH_PRODUCT_FAMILIES = createPromiseAction('product_families: FETCH_PRODUCT_FAMILIES')<FetchProductFamiliesPayload, FetchProductFamiliesResponse, any>()
export const FETCH_PRODUCT_FAMILY  = createPromiseAction('product_families: FETCH_PRODUCT_FAMILY')<FetchProductFamilyPayload, FetchProductFamilyResponse, any>()
export const CREATE_PRODUCT_FAMILY = createPromiseAction('product_families: CREATE_PRODUCT_FAMILY')<CreateProductFamilyPayload, FetchProductFamilyResponse, any>()
export const UPDATE_PRODUCT_FAMILY = createPromiseAction('product_families: UPDATE_PRODUCT_FAMILY')<UpdateProductFamilyPayload, FetchProductFamilyResponse, any>()