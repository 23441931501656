import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import {
  InvoiceItemAttributes,
  InvoiceAttributes,
  RepresentativeInvoicePageData,
  InvoiceStatusTypes,
  InvoiceChargeStatusTypes,
  Role,
  PaginationType,
  OrderOptions,
} from '../../../utils/constants';
import InvoiceTable from '../../table/InvoiceTable';
import InvoicesToolbar from '../../shared/InvoicesToolbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import SelectComponent from '../../input/form/select';
import SchoolExportListsComponent from '../../shared/SchoolExportListsComponent';
import { Tab, Tabs } from '@mui/material';
import TabPanel from '../../shared/TabPanel';
import InvoiceFormContainer from '../../../containers/InvoiceFormContainer';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    title: any;
    tabStyle: any
  } => ({
    view: {
      gap: '1rem',
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      alignSelf: 'self-start',
    },
    tabStyle: {
      '& .Mui-selected': {
        background: '#FDFEFF',
        borderRadius: '1rem 1rem 0 0',
      },
    },
  }),
);

const InvoicesView = ({
  loadPayments,
  statusFilterValue,
  setStatusFilterValue,
  chargeStatusFilterValue,
  setChargeStatusFilterValue,
  fromExpirationDateFilterValue,
  toExpirationDateFilterValue,
  setFromExpirationDateFilterValue,
  setToExpirationDateFilterValue,
  pageData,
  representativeFilterValue,
  setRepresentativeFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  handleChangePageSize,
  handlePageChange,
  handleRequestSort,
  pagination,
  order,
  orderBy,
  registrationFilterValue,
  setRegistrationFilterValue,
  setCodeFilterValue,
  codeFilterValue,
  selectedInvoices,
  setSelectedInvoices
}: {
  pageData: RepresentativeInvoicePageData;
  statusFilterValue: InvoiceStatusTypes[];
  chargeStatusFilterValue: InvoiceChargeStatusTypes[];
  fromExpirationDateFilterValue: Date | null;
  toExpirationDateFilterValue: Date | null;
  setChargeStatusFilterValue: React.Dispatch<React.SetStateAction<InvoiceChargeStatusTypes[]>>;
  setStatusFilterValue: React.Dispatch<React.SetStateAction<InvoiceStatusTypes[]>>;
  setFromExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  setToExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  loadPayments: (this_props?: {
    registration_filter?: string;
    status_filter_value?: InvoiceStatusTypes[];
    charge_status_filter?: InvoiceChargeStatusTypes[];
    from_expiration_date_filter?: Date | null;
    to_expiration_date_filter?: Date | null;
    representative_filter?: string;
    company_filter?: string;
  }) => Promise<void>;
  setRepresentativeFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setRegistrationFilterValue: React.Dispatch<React.SetStateAction<string>>;
  representativeFilterValue: string;
  setCodeFilterValue: React.Dispatch<React.SetStateAction<string>>;
  codeFilterValue: string;
  registrationFilterValue: string;
  companyFilterValue: string;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
  handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof InvoiceAttributes) => void
  pagination: PaginationType
  order: OrderOptions|string
  orderBy: string
  selectedInvoices: number[]
  setSelectedInvoices: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState('all_invoices');
  const [currentInvoice, setCurrentInvoice] = React.useState<InvoiceAttributes|null>(null)
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_admin,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const changeInvoiceStepTab = (invoice?: InvoiceAttributes, destiny = 'invoice_form') => {
    if(invoice){
      setCurrentInvoice(invoice)
    }
    handleTabChange(null, destiny)
  }

  return (
    <div className={classes.view}>
      <Tabs className={classes.tabStyle} style={{display: 'none'}} value={tab} onChange={handleTabChange}>
        <Tab label='Todas as faturas' value={'all_invoices'} />
        <Tab label='Ajuste de fatura' value={'invoice_form'} style={{display: 'none'}} />
      </Tabs>
      <TabPanel style={{ background: 'white' }} value={tab} index={'all_invoices'}>
      <span className={classes.title}>Faturas</span>
      {is_above_school_admin && (
        <div style={{ width: '30%' }}>
          <SelectComponent
          placeholder='Selecionar Unidade'
          small
          options={companies_options}
          input={{
            value: companyFilterValue,
            onChange: (e: any) => {
              setCompanyFilterValue(e.target.value);
              loadPayments({
                company_filter: e.target.value,
              });
            },
          }}
          />
        </div>
      )}
      <InvoicesToolbar
        chargeStatusFilter={chargeStatusFilterValue}
        fromExpirationDateFilterValue={fromExpirationDateFilterValue}
        handleFiltering={loadPayments}
        invoices={pageData.invoices}
        representativeFilter={representativeFilterValue}
        representatives={pageData.representatives}
        selectedInvoices={selectedInvoices}
        setChargeStatusFilter={setChargeStatusFilterValue}
        setFromExpirationDateFilterValue={setFromExpirationDateFilterValue}
        setRepresentativeFilter={setRepresentativeFilterValue}
        setStatusFilter={setStatusFilterValue}
        setToExpirationDateFilterValue={setToExpirationDateFilterValue}
        statusFilter={statusFilterValue}
        toExpirationDateFilterValue={toExpirationDateFilterValue}
        is_invoices_view
        setRegistrationFilter={setRegistrationFilterValue}
        registrationFilter={registrationFilterValue}
        codeFilter={codeFilterValue}
        setCodeFilter={setCodeFilterValue}
        setSelectedInvoices={setSelectedInvoices}
      />
      <InvoiceTable
        order={order as OrderOptions}
        orderBy={orderBy}
        pagination={pagination}
        invoicesDisposition={pageData.invoices}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        handleRequestSort={handleRequestSort}
        changeInvoiceStepTab={changeInvoiceStepTab}
        handleUpdateResources={() => loadPayments({})}
        invoice_items={pageData?.invoice_items as InvoiceItemAttributes[]}
        invoices={pageData?.invoices as InvoiceAttributes[]}
        is_representative_view={false}
        is_invoices_view
        setSelectedInvoices={setSelectedInvoices}
        selectedInvoices={selectedInvoices}
      />
      <SchoolExportListsComponent
        is_invoices_view
        is_registrations_view={false}
        defaultFilters={{
          statusFilter: statusFilterValue,
          chargeStatusFilter: chargeStatusFilterValue,
          registrationFilter: registrationFilterValue,
          representativeFilter: representativeFilterValue,
          fromExpirationDateFilter: fromExpirationDateFilterValue,
          toExpirationDateFilter: toExpirationDateFilterValue
        }}
      />
      </TabPanel>
      <TabPanel style={{ backgorund: 'white' }} value={tab} index={'invoice_form'}>
        <InvoiceFormContainer
          handleFetchInvoices={() => loadPayments({})}
          handleTabChange={handleTabChange}
          invoice={currentInvoice as InvoiceAttributes}
          load_resources
        />
      </TabPanel>
    </div>
  );
};

export default InvoicesView;