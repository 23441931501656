import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_CURRICULUM, FETCH_CURRICULUMS } from '../store/curriculums';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CurriculumAttributes, defaultPagination, PaginationType } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { get_company_search } from '../utils/functions';
import CurriculumsView from '../components/view/Secretary/CurriculumsView';

const CurriculumsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [curriculums, setCurriculums] = React.useState<CurriculumAttributes[]>([]);
  const [courseFilterValue, setCourseFilterValue] = React.useState('');
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchCurriculumsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
    }: {
      newPagination?: PaginationType;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        const curriculums = await dispatch(
          FETCH_CURRICULUMS.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[company_id_in]': companies_array,
                'q[course_name_cont]': courseFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = curriculums;
        const formattedCurriculums = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setCurriculums(formattedCurriculums);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar turmas',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue, courseFilterValue],
  );

  const deleteCurriculum = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_CURRICULUM.request({
            id,
          }),
        );CurriculumsView
        setLoading(false);
        dispatch(
          success({
            message: 'Matriz curricular removido com sucesso',
          }),
        );
        fetchCurriculumsMethod({});
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover matriz curricular',
          }),
        );
      }
    },
    [],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchCurriculumsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchCurriculumsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchCurriculumsMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <CurriculumsView
      curriculums={curriculums}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchCurriculumsMethod={fetchCurriculumsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
      deleteCurriculum={deleteCurriculum}
      courseFiltervalue={courseFilterValue}
      setCourseFilterValue={setCourseFilterValue}
    />
  );
};

export default CurriculumsContainer;
